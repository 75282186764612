<template>
  <transition name="modal-fade">
    <div v-if="isDataLoaded" class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-backdrop" @click.self="closeModal">
          <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
            <div class="modal-header" id="modalTitle">
              <h3>Регистрация участника на мероприятие: {{ event.name }}</h3>
              <button @click="closeModal" class="close-button">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body" id="modalDescription">
              <div class="body">
                <div class="home">
                  <form @submit.prevent="submitForm" class="event-form">
                    <!-- Форма участия в мероприятии -->
                    <div class="form-row">
                      <div class="form-group">
                        <label for="participationForm">
                          <i class="fas fa-users fa-sm"></i> Форма участия в мероприятии
                        </label>
                        <select id="participationForm" name="participationForm" class="form-input" :required="true"
                          v-model="formData.participationForm" @change="handleParticipationFormChange">
                          <option value="" disabled>Выберите...</option>
                          <option v-for="form in participationForms" :key="form.name" :value="form.name">{{ form.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- Тема доклада -->
                    <div v-if="showReportTopic" class="form-row">
                      <div class="form-group">
                        <label for="reportTopic">
                          <i class="fas fa-file-alt fa-sm"></i> Тема доклада/выступления/статьи
                        </label>
                        <input type="text" id="reportTopic" name="reportTopic" class="form-input"
                          placeholder="Тема доклада" :required="showReportTopic" v-model="reportTopic">
                      </div>
                    </div>

                    <!-- Согласие с обработкой персональных данных -->
                    <!-- <div class="form-row">
                      <div class="form-group checkbox-container">
                        <input type="checkbox" id="agree" name="agree" :required="true">
                        <label for="agree">Согласен с обработкой персональных данных</label>
                      </div>
                    </div> -->

                    <button type="submit" class="form-btn">Зарегистрироваться</button>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <p>Идет загрузка данных...</p>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';
export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {
        lastName: '',
        firstName: '',
        middleName: '',
        activityType: '',
        educationLevel: '',
        course: '',
        studyDirection: '',
        otherActivityType: '',
        country: '',
        fedDistrict: '',
        region: '',
        city: '',
        phone: '',
        email: '',
        academicDegree: '',
        academicTitle: '',
        organizationType: '',
        customOrganizationType: '',
        organizationName: '',
        participationForm: '',
        reportTopic: ''
      },
      showOrganizationTypeInput: false,
      showReportTopic: false,
      loading: false,
      isDataLoaded: false
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('references', [
      'participationForms'
    ]),
    filteredFedDistricts() {
      if (this.formData.country === 'Россия') {
        return this.fedDistricts.filter(district => district.name !== 'Другое');
      }
      return this.fedDistricts;
    }
  },
  methods: {
    ...mapActions('user', ['getUserByUid']),
    ...mapActions('references', [
      'getparticipationforms'
    ]),
    ...mapActions('eventregistration', ['submitEventRegistration']),
    async submitForm() {
      this.loading = true; // Показываем колесо загрузки
      try {
        const formData = {
          userid: this.user.uid,
          eventid: this.event.uid,
          participationform: this.formData.participationForm,
          reporttopic: this.reportTopic,
          ...this.formData
        };

        const result = await this.submitEventRegistration(formData);
        if (result.success) {
          Swal.fire({
            icon: 'success',
            title: 'Успех',
            text: 'Вы успешно зарегистрировались на мероприятие! Теперь вам доступен QR-код для входа',
            confirmButtonText: 'ОК',
            confirmButtonColor: '#3c218c',
            customClass: {
              popup: 'custom-swal-popup',
              title: 'custom-swal-title',
              content: 'custom-swal-content',
              confirmButton: 'custom-swal-confirm-button',
            },
          });
          this.$emit('registration-success');
          this.closeModal();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: 'Произошла ошибка. Пожалуйста, попробуйте позже или обратитесь к администратору.',
            confirmButtonText: 'ОК',
            confirmButtonColor: '#3c218c',
            customClass: {
              popup: 'custom-swal-popup',
              title: 'custom-swal-title',
              content: 'custom-swal-content',
              confirmButton: 'custom-swal-confirm-button',
            },
          });
        }
      } catch (error) {
        console.error('Ошибка при регистрации:', error);
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Произошла ошибка. Пожалуйста, попробуйте позже или обратитесь к администратору.',
          confirmButtonText: 'ОК',
          confirmButtonColor: '#3c218c',
        });
      } finally {
        this.loading = false; // Скрываем колесо загрузки
      }
    },
    closeModal() {
      this.$emit('close');
    },
    handleParticipationFormChange() {
      const participationForm = this.participationForms.find(form => form.name === this.formData.participationForm);
      this.showReportTopic = participationForm && (participationForm.name.includes('с выступлением') || participationForm.name.includes('с выступлением'));
    }
  },
  async mounted() {
    this.loading = true; // Показываем колесо загрузки
    try {
      await this.getUserByUid();
      await this.getparticipationforms();

      this.formData = {
        ...this.formData,
        lastname: this.user.surname || '',
        firstname: this.user.name || '',
        middlename: this.user.thirdname || '',
        country: this.user.country || '',
        phone: this.user.phone || '',
        email: this.user.email || '',
        organizationname: this.user.organization || '',
        activitytype: this.user.activitytype || '',
        educationlevel: this.user.educationlevel || '',
        course: this.user.course || '',
        studydirection: this.user.studydirection || '',
        otheractivitytype: this.user.otheractivitytype || '',
        feddistrict: this.user.feddistrict || '',
        region: this.user.region || '',
        city: this.user.city || '',
        academicdegree: this.user.academicdegree || '',
        academictitle: this.user.academictitle || '',
        organizationtype: this.user.organizationtype || '',
        customorganizationtype: this.user.customorganizationtype || ''
      };

      this.isDataLoaded = true; // Данные загружены
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ошибка',
        text: 'Не удалось загрузить данные. Пожалуйста, попробуйте позже.',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#3c218c',
      });
    } finally {
      this.loading = false; // Скрываем колесо загрузки
    }
  },
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.home {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.event-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-group {
  flex: 1;
}

.form-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.form-btn {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.form-btn:hover {
  background-color: #ef7d00;
}

.file-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f8f8f8;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.file-input::-webkit-file-upload-button {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.file-input::-webkit-file-upload-button:hover {
  background-color: #3c218c;
}

/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  /* Убираем скролл внутри модального окна */
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  /* Выравниваем элементы по центру по вертикали */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-header h3 {
  margin: 0;
  /* Убираем отступы у заголовка */
  flex-grow: 1;
  /* Заголовок занимает все доступное пространство */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  /* Добавляем вертикальный скролл, если контент не помещается */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  /* Белый цвет крестика */
  margin-left: auto;
  /* Крестик справа */
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  cursor: pointer;
}

.btn-add,
.btn-remove {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
}

.btn-add:hover,
.btn-remove:hover {
  background-color: #ef7d00;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

label {
  margin-bottom: 5px;
  display: block;
}
</style>