<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <h3>Ваш QR-код для входа на мероприятие</h3>
          <button @click="closeModal" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div v-if="user && event">
              <p>Вы авторизованы как: {{ user.surname }} {{ user.name }} {{ user.thirdname }}</p>
              <p>Мероприятие: {{ event.name }}</p>
              <p>Время генерации: {{ date }} {{ time }}</p>
              <p>Ваш QR-код для входа на мероприятие:</p>
              <div v-if="loading" class="loading-spinner">
                Загрузка...
              </div>
              <div class="qr-code-container" @contextmenu.prevent>
                <img :src="qrCodeData" alt="QR Code" class="qr-code-image" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import QRCode from 'qrcode';
import CryptoJS from 'crypto-js';

export default {
  name: 'modal',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      qrCodeData: null,
      date: null,
      time: null,
      loading: false,
      intervalId: null,
      secretKey: 'your-secret-key' // Секретный ключ для шифрования
    };
  },
  async created() {
    await this.getUserByUid();
    await this.generateQRCode();
  },
  methods: {
    closeModal() {
      this.qrCodeData = null;
      this.date = null;
      this.time = null;
      this.loading = false;

      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }

      this.$emit('close');
    },
    ...mapActions({
      getUserByUid: 'user/getUserByUid'
    }),
    encryptData(data) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
    },
    generateQRCode() {
      if (!this.user) {
        console.error("Пользователь не авторизован");
        return;
      }

      this.loading = true;
      const now = new Date();
      const date = now.toLocaleDateString();
      const time = now.toLocaleTimeString();
      const data = {
        useruid: this.user.uid,
        eventuid: this.event.uid,
        generatedAt: now.toLocaleDateString() + " " + now.toLocaleTimeString(),
      };

      // Шифруем данные
      const encryptedData = this.encryptData(data);

      QRCode.toDataURL(encryptedData, { errorCorrectionLevel: 'H', margin: 1, scale: 10 }, (err, url) => {
        if (err) throw err;
        this.qrCodeData = url;
        this.date = date;
        this.time = time;
        this.loading = false;
      });

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        this.generateQRCode();
      }, 10000);
    },
  },
  computed: {
    ...mapState('user', ['user']),
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
};
</script>

<style scoped>
.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  /* Белый цвет крестика */
  margin-left: auto;
  /* Крестик справа */
}


.loading-spinner {
  text-align: center;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-image {
  width: 300px;
  /* Установите желаемый размер */
  height: 300px;
  /* Установите желаемый размер */
}

.form-input {
  padding: 15px;
  /* Increased padding for better readability */
  margin: 0;
  border: none;
  /* Removed border for input fields */
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  /* Set input background to light gray */
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  /* Убираем скролл внутри модального окна */
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  /* Выравниваем элементы по центру по вертикали */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-header h3 {
  margin: 0;
  /* Убираем отступы у заголовка */
  flex-grow: 1;
  /* Заголовок занимает все доступное пространство */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  text-align: left;
  /* Добавляем вертикальный скролл, если контент не помещается */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  /* Белый цвет крестика */
  margin-left: auto;
  /* Крестик справа */
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  cursor: pointer;
}
</style>