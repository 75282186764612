<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <h3>Сканирование QR-кода</h3>
          <button @click="closeModal" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div id="qr-reader" style="width: 100%"></div>
            <div v-if="scannedData">
              <p>Информация из QR-кода:</p>
              <div class="qr-data">
                <p><strong>ФИО пользователя:</strong> {{ userFullName }}</p>
                <p><strong>Мероприятие:</strong> {{ eventName }}</p>
                <p><strong>Время генерации:</strong> {{ parsedData.generatedAt }}</p>
              </div>
            </div>
          </div>
          <div class="modal-actions" v-if="scannedData">
            <button type="button" class="btn-green" @click="confirmScan" aria-label="Confirm scan">
              Подтвердить
            </button>
            <button type="button" class="btn-red" @click="cancelScan" aria-label="Cancel scan">
              Отменить
            </button>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Html5QrcodeScanner } from 'html5-qrcode';
import { mapActions, mapState } from 'vuex';
import CryptoJS from 'crypto-js';

export default {
  name: 'QrScannerModal',
  computed: {
    ...mapState('user', ['user']),
    userFullName() {
      if (this.parsedData && this.parsedData.user) {
        return `${this.parsedData.user.surname} ${this.parsedData.user.name} ${this.parsedData.user.thirdname}`;
      }
      return 'Неизвестный пользователь';
    },
    eventName() {
      if (this.parsedData && this.parsedData.event) {
        return this.parsedData.event.name; // Выводим название мероприятия
      }
      return 'Неизвестное мероприятие';
    },
  },
  data() {
    return {
      scannedData: null,
      parsedData: null,
      html5QrcodeScanner: null,
      secretKey: 'your-secret-key', // Секретный ключ для расшифровки
      isScanning: false, // Флаг для отслеживания состояния сканирования
    };
  },
  mounted() {
    this.startScanner();
  },
  beforeDestroy() {
    this.stopScanner();
  },
  methods: {
    closeModal() {
      this.stopScanner();
      this.$emit('close');
    },
    startScanner() {
      this.html5QrcodeScanner = new Html5QrcodeScanner(
        "qr-reader",
        {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          disableFlip: true, // Отключаем возможность сканирования из файла
        },
        /* verbose= */ false
      );

      this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
      this.isScanning = true;
    },
    stopScanner() {
      if (this.html5QrcodeScanner) {
        this.html5QrcodeScanner.clear();
        this.html5QrcodeScanner = null;
      }
      this.isScanning = false;
    },
    decryptData(encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (!decryptedData) {
          throw new Error('Не удалось расшифровать данные');
        }
        return JSON.parse(decryptedData);
      } catch (error) {
        console.error('Ошибка при расшифровке данных:', error);
        return null;
      }
    },
    async onScanSuccess(decodedText, decodedResult) {
      try {
        // Расшифровываем данные
        const parsedData = this.decryptData(decodedText);
        if (!parsedData) {
          throw new Error('Неверный формат данных');
        }

        // Загружаем данные пользователя и мероприятия
        const user = await this.getUserByUidForReport(parsedData.useruid);
        const event = await this.fetchEventDetails(parsedData.eventuid);

        // Сохраняем данные
        this.parsedData = {
          ...parsedData,
          user,
          event,
        };
        this.scannedData = decodedText;
      } catch (error) {
        console.error('Ошибка при обработке данных:', error);
        alert('Ошибка при сканировании QR-кода. Проверьте данные.');
      }
    },
    onScanFailure(error) {
      console.warn(`Ошибка сканирования: ${error}`);
      if (error.message.includes('No MultiFormat Readers were able to detect the code')) {
        alert('QR-код не распознан. Убедитесь, что код находится в фокусе камеры.');
      } else {
        alert('Ошибка сканирования. Попробуйте снова.');
      }
    },
    async confirmScan() {
      if (this.parsedData) {
        try {
          await this.sendAttendanceData(this.parsedData);
          this.resetScan();
        } catch (error) {
          console.error('Ошибка при отправке данных:', error);
          alert('Ошибка при отправке данных. Попробуйте снова.');
        }
      }
    },
    cancelScan() {
      this.resetScan();
    },
    resetScan() {
      this.scannedData = null;
      this.parsedData = null;
    },
    async sendAttendanceData(data) {
      try {
        await this.addAttendance({
          useruid: data.useruid,
          scanuid: this.user.uid,
          eventuid: data.eventuid,
        });
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
      }
    },
    ...mapActions({
      addAttendance: 'attendance/addAttendance',
      getUserByUidForReport: 'user/getUserByUidForReport',
      fetchEventDetails: 'event/fetchEventDetails',
    }),
  },
};
</script>


<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  margin-left: auto;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-red {
  color: white;
  background: #FF4136;
  border: 1px solid #FF4136;
  border-radius: 2px;
  padding: 10px 20px;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.qr-data {
  margin-top: 20px;
  text-align: left;
}

.qr-data p {
  margin: 5px 0;
}
</style>