export default {
  namespaced: true,
  state: {
    attendances: [],
  },
  getters: {
    attendances: state => state.attendances,
  },
  mutations: {
    SET_ATTENDANCES(state, attendances) {
      state.attendances = attendances;
    },
    ADD_ATTENDANCE(state, attendance) {
      state.attendances.push(attendance);
    },
  },
  actions: {
    async addAttendance({ commit }, { useruid, scanuid, eventuid }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/add`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            useruid,
            scanuid,
            eventuid,
            // eventrole,
          }),
        });

        if (response.ok) {
          const newAttendance = await response.json();
          commit('ADD_ATTENDANCE', newAttendance);
        } else {
          console.error('Ошибка при добавлении записи о посещении:', response.statusText);
        }
      } catch (error) {
        console.error('Ошибка при добавлении записи о посещении:', error);
      }
    },
    async getAllAttendances({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/getallattendance`);
        const attendances = await response.json();
        commit('SET_ATTENDANCES', attendances);
      } catch (error) {
        console.error('Ошибка при получении записей о посещении:', error);
        commit('SET_ATTENDANCES', []); // Устанавливаем пустой массив в случае ошибки
      }
    },
    async getAttendanceByEventUid({ commit }, eventuid) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/event/${eventuid}`);
        const attendances = await response.json();
        commit('SET_ATTENDANCES', attendances);
        return attendances; // Возвращаем данные для использования в компоненте
      } catch (error) {
        console.error('Ошибка при получении записей о посещении по eventuid:', error);
        commit('SET_ATTENDANCES', []); // Устанавливаем пустой массив в случае ошибки
        return [];
      }
    },
    async getAttendanceByUserUid({ commit }, useruid) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/user/${useruid}`);
        const attendances = await response.json();
        commit('SET_ATTENDANCES', attendances);
        return attendances; // Возвращаем данные для использования в компоненте
      } catch (error) {
        console.error('Ошибка при получении записей о посещении по useruid:', error);
        commit('SET_ATTENDANCES', []); // Устанавливаем пустой массив в случае ошибки
        return [];
      }
    },
  },
};