<template>
  <footer class="footer">
    <div class="footer-content">
      <!-- Секция с контактами -->
      <div class="footer-section">
        <h3>Контакты</h3>
        <p>По всем вопросам обращайтесь:</p>
        <p>Email: <a href="mailto:afishkahelper@gmail.com">afishkahelper@gmail.com</a></p>
        <!-- <p>Телефон: <a href="tel:+71234567890" @click.prevent="showUnderConstruction">+7 (123) 456-78-90</a></p> -->
      </div>

      <!-- Секция с социальными сетями -->
      <div class="footer-section">
        <h3>Мы в соцсетях</h3>
        <div class="social-icons">
          <a href="#" target="_blank" aria-label="ВКонтакте" @click.prevent="showUnderConstruction">
            <i class="fab fa-vk"></i>
          </a>
          <a href="#" target="_blank" aria-label="Telegram" @click.prevent="showUnderConstruction">
            <i class="fab fa-telegram"></i>
          </a>
        </div>
      </div>

      <!-- Секция с полезными ссылками -->
      <div class="footer-section">
        <h3>Полезные ссылки</h3>
        <ul>
          <li><a href="#" @click.prevent="showUnderConstruction">О нас</a></li>
          <li><a href="#" @click.prevent="showUnderConstruction">Помощь</a></li>
          <li><a href="#" @click.prevent="showUnderConstruction">Политика конфиденциальности</a></li>
          <li><a href="#" @click.prevent="showUnderConstruction">Условия использования</a></li>
        </ul>
      </div>
    </div>

    <!-- Копирайт -->
    <div class="footer-bottom">
      <p>&copy; 2023 AfishkaHelper. Все права защищены.</p>
    </div>
  </footer>
</template>

<script>
import Swal from 'sweetalert2'; // Импортируем SweetAlert2

export default {
  methods: {
    showUnderConstruction() {
      Swal.fire({
        icon: 'info', // Иконка "информация"
        title: 'Раздел в разработке',
        text: 'Этот раздел пока недоступен. Пожалуйста, попробуйте позже.',
        confirmButtonText: 'ОК', // Текст кнопки
        confirmButtonColor: '#3c218c', // Цвет кнопки
        customClass: {
          popup: 'custom-swal-popup', // Класс для контейнера
          title: 'custom-swal-title', // Класс для заголовка
          content: 'custom-swal-content', // Класс для текста
          confirmButton: 'custom-swal-confirm-button', // Класс для кнопки
        },
      });
    },
  },
};
</script>

<style scoped>
/* Основные стили для футера */
.footer {
  background-color: #3c218c;
  color: #fff;
  padding: 5px 0;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30%;
  /* Для гибкого размещения внизу */
}

/* Контент футера */
.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px;
}

/* Секции футера */
.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 5x;
}

.footer-section h3 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
}

.footer-section p,
.footer-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #ef7d00;
  /* Цвет при наведении */
}

/* Иконки социальных сетей */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.social-icons a {
  font-size: 24px;
  color: #fff;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ef7d00;
  /* Цвет при наведении */
}

/* Нижняя часть футера */
.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 5px 0;
  margin-top: 5px;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}
</style>