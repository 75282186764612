<template>
    <div>
      <navbar />
      <div v-if="user" class="combined-report">
        <h1>Отчет по мероприятию "{{ eventName }}"</h1>
        <button @click="downloadExcel">Скачать в Excel</button>
        <table v-if="registrations.length > 0">
          <thead>
            <tr>
              <!-- Колонки для регистраций -->
              <th>Email</th>
              <th>Фамилия</th>
              <th>Имя</th>
              <th>Отчество</th>
              <th>Тип активности</th>
              <th>Уровень образования</th>
              <th>Курс</th>
              <th>Направление обучения</th>
              <th>Другой тип активности</th>
              <th>Страна</th>
              <th>Федеральный округ</th>
              <th>Регион</th>
              <th>Город</th>
              <th>Телефон</th>
              <th>Академическая степень</th>
              <th>Академическое звание</th>
              <th>Тип организации</th>
              <th>Другой тип организации</th>
              <th>Название организации</th>
              <th>Форма участия</th>
              <th>Тема доклада</th>
  
              <!-- Колонки для присутствия -->
              <th>Сканер вход</th>
              <th>Сканер выход</th>
              <th>Время входа</th>
              <th>Время выхода</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="registration in registrations" :key="registration.id">
              <!-- Данные регистрации -->
              <td>{{ registration.email }}</td>
              <td>{{ registration.lastname }}</td>
              <td>{{ registration.firstname }}</td>
              <td>{{ registration.middlename }}</td>
              <td>{{ registration.activitytype }}</td>
              <td>{{ registration.educationlevel }}</td>
              <td>{{ registration.course }}</td>
              <td>{{ registration.studydirection }}</td>
              <td>{{ registration.otheractivitytype }}</td>
              <td>{{ registration.country }}</td>
              <td>{{ registration.feddistrict }}</td>
              <td>{{ registration.region }}</td>
              <td>{{ registration.city }}</td>
              <td>{{ registration.phone }}</td>
              <td>{{ registration.academicdegree }}</td>
              <td>{{ registration.academictitle }}</td>
              <td>{{ registration.organizationtype }}</td>
              <td>{{ registration.customorganizationtype }}</td>
              <td>{{ registration.organizationname }}</td>
              <td>{{ registration.participationform }}</td>
              <td>{{ registration.reporttopic }}</td>
  
              <!-- Данные присутствия -->
              <td>{{ getAttendanceData(registration.useruid).scannerEnterName }}</td>
              <td>{{ getAttendanceData(registration.useruid).scannerExitName }}</td>
              <td>{{ getAttendanceData(registration.useruid).scannedenter }}</td>
              <td>{{ getAttendanceData(registration.useruid).scannedexit }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <p>Загрузка данных...</p>
        </div>
      </div>
      <div v-else class="auth-required-message">
        <h2>Доступ запрещен</h2>
        <p v-if="!user">Для просмотра этой страницы необходимо авторизоваться.</p>
        <p v-else>У вас недостаточно прав для просмотра этой страницы.</p>
        <button class="form-btn" @click="goToLogin">
          <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import navbar from "@/components/navbar.vue";
  import { mapActions, mapGetters, mapState } from 'vuex';
  import * as XLSX from 'xlsx';
  
  export default {
    components: {
      navbar,
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapGetters('eventregistration', ['registrations']),
      ...mapGetters('attendance', ['attendances']),
      ...mapGetters('user', ['users']),
      eventName() {
        return decodeURIComponent(this.$route.params.eventName);
      },
      eventId() {
        return this.$route.params.eventId;
      },
    },
    methods: {
      ...mapActions({
        getUserByUid: 'user/getUserByUid',
        getEventRegistrationsByEventUid: 'eventregistration/getEventRegistrationsByEventUid',
        getAttendanceByEventUid: 'attendance/getAttendanceByEventUid',
        getAllUsers: 'user/getAllUsers',
      }),
      // Метод для получения данных о посещении по useruid
      getAttendanceData(useruid) {
        const attendance = this.attendances.find(a => a.useruid === useruid) || {};
        const scannerEnterUser = this.users.find(u => u.uid === attendance.scanuidenter);
        const scannerExitUser = this.users.find(u => u.uid === attendance.scanuidexit);
  
        return {
          scannerEnterName: scannerEnterUser ? `${scannerEnterUser.surname} ${scannerEnterUser.name} ${scannerEnterUser.thirdname}` : '',
          scannerExitName: scannerExitUser ? `${scannerExitUser.surname} ${scannerExitUser.name} ${scannerExitUser.thirdname}` : '',
          scannedenter: attendance.scannedenter ? this.formatDate(attendance.scannedenter) : '',
          scannedexit: attendance.scannedexit ? this.formatDate(attendance.scannedexit) : '',
        };
      },
      downloadExcel() {
        const data = this.registrations.map(registration => {
          const attendanceData = this.getAttendanceData(registration.useruid);
  
          return {
            'Email': registration.email,
            'Фамилия': registration.lastname,
            'Имя': registration.firstname,
            'Отчество': registration.middlename,
            'Тип активности': registration.activitytype,
            'Уровень образования': registration.educationlevel,
            'Курс': registration.course,
            'Направление обучения': registration.studydirection,
            'Другой тип активности': registration.otheractivitytype,
            'Страна': registration.country,
            'Федеральный округ': registration.feddistrict,
            'Регион': registration.region,
            'Город': registration.city,
            'Телефон': registration.phone,
            'Академическая степень': registration.academicdegree,
            'Академическое звание': registration.academictitle,
            'Тип организации': registration.organizationtype,
            'Другой тип организации': registration.customorganizationtype,
            'Название организации': registration.organizationname,
            'Форма участия': registration.participationform,
            'Тема доклада': registration.reporttopic,
            'Сканер вход': attendanceData.scannerEnterName,
            'Сканер выход': attendanceData.scannerExitName,
            'Время входа': attendanceData.scannedenter,
            'Время выхода': attendanceData.scannedexit,
          };
        });
  
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Отчет');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
        const fileName = `Отчет по мероприятию "${this.eventName}".xlsx`;
        this.saveAsExcelFile(excelBuffer, fileName);
      },
      saveAsExcelFile(buffer, fileName) {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      formatDate(date) {
        return date ? new Date(date).toLocaleString() : '';
      },
      goToLogin() {
        this.$router.push('/login');
      },
    },
    async created() {
      await this.getUserByUid();
      await this.getAllUsers();
      await this.getEventRegistrationsByEventUid(this.eventId);
      await this.getAttendanceByEventUid(this.eventId);
    },
    mounted() {
      window.scrollTo(0, 0);
    },
  };
  </script>
  
  <style scoped>
  .combined-report {
    padding: 20px;
    padding-top: 50px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    background-color: #3c218c;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  button {
    background-color: #3c218c;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  button:hover {
    background-color: #5a3aa8;
  }
  
  .auth-required-message {
    text-align: center;
    padding: 20px;
    padding-top: 50px;
  }
  
  .auth-required-message h2 {
    color: #ff0000;
  }
  
  .form-btn {
    background-color: #3c218c;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .form-btn:hover {
    background-color: #5a3aa8;
  }
  </style>