<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <h3>Редактирование данных</h3>
          <button @click="closeModal" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <!-- Основные данные -->
            <div class="form-group">
              <label for="surname">Фамилия <span class="required">*</span></label>
              <input id="surname" type="text" class="form-input" placeholder="Введите фамилию" required v-model="surname" />
            </div>
            <div class="form-group">
              <label for="name">Имя <span class="required">*</span></label>
              <input id="name" type="text" class="form-input" placeholder="Введите имя" required v-model="name" />
            </div>
            <div class="form-group">
              <label for="thirdname">Отчество</label>
              <input id="thirdname" type="text" class="form-input" placeholder="Введите отчество" v-model="thirdname" />
            </div>
            <div class="form-group">
              <label for="email">Email <span class="required">*</span></label>
              <input id="email" type="email" class="form-input" placeholder="Введите email" required v-model="email" />
            </div>

            <!-- Организация -->
            <div class="form-group">
              <label for="organizationType">Тип организации <span class="required">*</span></label>
              <select id="organizationType" class="form-input" required v-model="organizationtype" @change="handleOrganizationTypeChange">
                <option value="" disabled>Выберите...</option>
                <option v-for="type in organizationTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
              </select>
            </div>
            <div v-if="showOrganizationTypeInput" class="form-group">
              <label for="customOrganizationType">Укажите тип организации <span class="required">*</span></label>
              <input type="text" id="customOrganizationType" class="form-input" placeholder="Укажите тип организации" required v-model="customorganizationtype" />
            </div>
            <div class="form-group">
              <label for="organizationName">Полное наименование организации <span class="required">*</span></label>
              <input type="text" id="organizationName" class="form-input" placeholder="Наименование организации" required v-model="organizationname" />
            </div>

            <!-- Вид деятельности -->
            <div class="form-group">
              <label for="activityType">Вид деятельности <span class="required">*</span></label>
              <select id="activityType" class="form-input" required v-model="activitytype" @change="handleActivityTypeChange">
                <option value="" disabled>Выберите...</option>
                <option value="Студент">Студент</option>
                <option value="Преподаватель">Преподаватель</option>
                <option value="Другое">Другое (указать!)</option>
              </select>
            </div>

            <!-- Дополнительные поля для студента -->
            <div v-if="activitytype === 'Студент'" class="form-group">
              <label for="educationLevel">Уровень образования <span class="required">*</span></label>
              <select id="educationLevel" class="form-input" required v-model="educationlevel">
                <option value="" disabled>Выберите...</option>
                <option value="Бакалавр">Бакалавр</option>
                <option value="Магистр">Магистр</option>
                <option value="Аспирант">Аспирант</option>
              </select>
            </div>
            <div v-if="activitytype === 'Студент'" class="form-group">
              <label for="course">Курс <span class="required">*</span></label>
              <select id="course" class="form-input" required v-model="course">
                <option value="" disabled>Выберите...</option>
                <option v-for="n in 5" :key="n" :value="n">{{ n }}</option>
              </select>
            </div>
            <div v-if="activitytype === 'Студент'" class="form-group">
              <label for="studyDirection">Направление обучения <span class="required">*</span></label>
              <select id="studyDirection" class="form-input" required v-model="studydirection">
                <option value="" disabled>Выберите...</option>
                <option v-for="direction in studyDirections" :key="direction.code" :value="direction.code">{{ direction.name }}</option>
              </select>
            </div>

            <!-- Дополнительные поля для преподавателя -->
            <div v-if="activitytype === 'Преподаватель'">
              <div class="form-group">
                <label for="academicDegree">Ученая степень <span class="required">*</span></label>
                <select id="academicDegree" class="form-input" required v-model="academicdegree">
                  <option value="" disabled>Выберите...</option>
                  <option v-for="degree in academicDegrees" :key="degree.id" :value="degree.id">{{ degree.name }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="academicTitle">Ученое звание <span class="required">*</span></label>
                <select id="academicTitle" class="form-input" required v-model="academictitle">
                  <option value="" disabled>Выберите...</option>
                  <option v-for="title in academicTitles" :key="title.id" :value="title.id">{{ title.name }}</option>
                </select>
              </div>
            </div>

            <!-- Дополнительные поля для "Другое" -->
            <div v-if="activitytype === 'Другое'" class="form-group">
              <label for="otherActivityType">Укажите вид деятельности <span class="required">*</span></label>
              <input type="text" id="otherActivityType" class="form-input" placeholder="Укажите вид деятельности" required v-model="otheractivitytype" />
            </div>

            <!-- Факультет -->
            <div class="form-group">
              <label for="faculty">Факультет (если есть)</label>
              <input id="faculty" type="text" class="form-input" placeholder="Введите факультет" v-model="faculty" />
            </div>

            <!-- Группа -->
            <div class="form-group">
              <label for="user_group">Группа или класс (если есть)</label>
              <input id="user_group" type="text" class="form-input" placeholder="Введите группу или класс" v-model="user_group" />
            </div>

            <!-- Страна -->
            <div class="form-group">
              <label for="country">Страна <span class="required">*</span></label>
              <select id="country" class="form-input" required v-model="country">
                <option value="" disabled>Выберите...</option>
                <option v-for="country in countries" :key="country.name" :value="country.name">{{ country.name }}</option>
              </select>
            </div>

            <!-- Федеральный округ -->
            <div class="form-group">
              <label for="fedDistrict">Федеральный округ <span class="required">*</span></label>
              <select id="fedDistrict" class="form-input" required v-model="feddistrict" @change="loadRegions">
                <option value="" disabled>Выберите...</option>
                <option v-for="district in filteredFedDistricts" :key="district.id" :value="district.id">{{ district.name }}</option>
              </select>
            </div>

            <!-- Регион -->
            <div class="form-group">
              <label for="region">Регион <span class="required">*</span></label>
              <select id="region" class="form-input" required v-model="region" :disabled="!feddistrict">
                <option value="" disabled>Выберите...</option>
                <option v-for="reg in regions" :key="reg.id" :value="reg.id">{{ reg.name }}</option>
              </select>
            </div>

            <!-- Населенный пункт -->
            <div class="form-group">
              <label for="city">Населенный пункт <span class="required">*</span></label>
              <input type="text" id="city" class="form-input" placeholder="Населенный пункт" required v-model="city" :disabled="!region" />
            </div>

            <!-- Телефон -->
            <div class="form-group">
              <label for="phone">Телефон</label>
              <input type="tel" id="phone" class="form-input" placeholder="Телефон" required v-model="phone" @input="formatPhone" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="form-btn" @click="updateUser">Сохранить</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'EditUserDataModal',
  data() {
    return {
      email: '',
      surname: '',
      name: '',
      thirdname: '',
      organizationtype: '',
      customorganizationtype: '',
      organizationname: '',
      activitytype: '',
      educationlevel: '',
      course: '',
      studydirection: '',
      otheractivitytype: '',
      faculty: '',
      user_group: '',
      country: '',
      feddistrict: '',
      region: '',
      city: '',
      phone: '',
      academicdegree: '',
      academictitle: '',
      showOrganizationTypeInput: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('references', ['countries', 'fedDistricts', 'regions', 'academicDegrees', 'academicTitles', 'organizationTypes', 'studyDirections']),
    filteredFedDistricts() {
      if (!this.fedDistricts) return [];
      if (this.country === 'Россия') {
        return this.fedDistricts.filter(district => district.name !== 'Другое');
      }
      return this.fedDistricts;
    },
  },
  methods: {
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
      updateUserInStore: 'user/updateUser',
      getcountries: 'references/getcountries',
      getfeddistricts: 'references/getfeddistricts',
      getregionsbyfeddistrictid: 'references/getregionsbyfeddistrictid',
      getacademicdegrees: 'references/getacademicdegrees',
      getacademictitles: 'references/getacademictitles',
      getorganizationtypes: 'references/getorganizationtypes',
    }),
    closeModal() {
      this.$emit('close');
    },
    async updateUser() {
      const userData = {
        uid: this.user.uid,
        email: this.email,
        surname: this.surname,
        name: this.name,
        thirdname: this.thirdname,
        organizationtype: this.organizationtype,
        customorganizationtype: this.customorganizationtype,
        organizationname: this.organizationname,
        activitytype: this.activitytype,
        educationlevel: this.educationlevel,
        course: this.course,
        studydirection: this.studydirection,
        otheractivitytype: this.otheractivitytype,
        faculty: this.faculty,
        user_group: this.user_group,
        country: this.country,
        feddistrict: this.feddistrict,
        region: this.region,
        city: this.city,
        phone: this.phone,
        academicdegree: this.academicdegree,
        academictitle: this.academictitle,
      };

      try {
        await this.updateUserInStore(userData);
        Swal.fire({
          icon: 'success',
          title: 'Данные обновлены',
          text: 'Ваши данные успешно обновлены.',
          confirmButtonText: 'ОК',
          confirmButtonColor: '#3c218c',
          customClass: {
            popup: 'custom-swal-popup',
            title: 'custom-swal-title',
            content: 'custom-swal-content',
            confirmButton: 'custom-swal-confirm-button',
          },
        });
        this.closeModal();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Произошла ошибка при обновлении данных. Пожалуйста, попробуйте позже.',
          confirmButtonText: 'ОК',
          confirmButtonColor: '#3c218c',
          customClass: {
            popup: 'custom-swal-popup',
            title: 'custom-swal-title',
            content: 'custom-swal-content',
            confirmButton: 'custom-swal-confirm-button',
          },
        });
      }
    },
    handleOrganizationTypeChange() {
      this.showOrganizationTypeInput = this.organizationtype === '13';
    },
    handleActivityTypeChange() {
      if (this.activitytype === 'Студент') {
        this.academicdegree = '';
        this.academictitle = '';
      } else if (this.activitytype === 'Преподаватель') {
        this.educationlevel = '';
        this.course = '';
        this.studydirection = '';
      } else if (this.activitytype === 'Другое') {
        this.academicdegree = '';
        this.academictitle = '';
        this.educationlevel = '';
        this.course = '';
        this.studydirection = '';
      }
    },
    formatPhone(event) {
      let phone = event.target.value.replace(/\D/g, '');
      if (phone.length > 0 && phone[0] !== '+') {
        phone = '+' + phone;
      }
      this.phone = phone;
    },
    async loadRegions() {
      if (!this.feddistrict) return;
      await this.getregionsbyfeddistrictid({ feddistrictid: this.feddistrict });
    },
    setUserData() {
      const user = this.user;
      this.email = user.email || '';
      this.surname = user.surname || '';
      this.name = user.name || '';
      this.thirdname = user.thirdname || '';
      this.organizationtype = user.organizationtype || '';
      this.customorganizationtype = user.customorganizationtype || '';
      this.organizationname = user.organizationname || '';
      this.activitytype = user.activitytype || '';
      this.educationlevel = user.educationlevel || '';
      this.course = user.course || '';
      this.studydirection = user.studydirection || '';
      this.otheractivitytype = user.otheractivitytype || '';
      this.faculty = user.faculty || '';
      this.user_group = user.user_group || '';
      this.country = user.country || '';
      this.feddistrict = user.feddistrict || '';
      this.region = user.region || '';
      this.city = user.city || '';
      this.phone = user.phone || '';
      this.academicdegree = user.academicdegree || '';
      this.academictitle = user.academictitle || '';
    },
  },
  async created() {
    await this.getcountries();
    await this.getfeddistricts();
    await this.getacademicdegrees();
    await this.getacademictitles();
    await this.getorganizationtypes();
    console.log(this.user);
    this.setUserData();
  },
};
</script>
<style scoped>
.info-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Отступ между элементами внутри info-row */
  padding: 10px;
}

.comment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.comment-text {
  flex-grow: 1;
}

.comment-date {
  font-size: 0.8em;
  color: #999;
  margin-right: 10px;
}

.btn-red {
  color: white;
  background: #ff4d4f;
  border: 1px solid #eeeeee;
  border-radius: 20px;
}

/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  /* Убираем скролл внутри модального окна */
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  /* Выравниваем элементы по центру по вертикали */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-header h3 {
  margin: 0;
  /* Убираем отступы у заголовка */
  flex-grow: 1;
  /* Заголовок занимает все доступное пространство */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  /* Добавляем вертикальный скролл, если контент не помещается */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  /* Белый цвет крестика */
  margin-left: auto;
  /* Крестик справа */
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
}

form {
  display: flex;
  padding: 20px;
  flex-direction: column;
  width: 400px;
  row-gap: 20px;
  border: 1px solid #ef7d00;
  /* Added border with orange color */
  border-radius: 20px;
  background-color: #fff;
  /* Set form background to white */
  margin-bottom: 100px;
}

.content {
  margin-top: 70px;
  text-align: center;
}

h2 {
  color: #000000;
  font-size: 2em;
}

p {
  color: #000000;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-input {
  padding: 15px;
  /* Increased padding for better readability */
  margin: 0;
  border: none;
  /* Removed border for input fields */
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  /* Set input background to light gray */
}

.form-btn {
  background-color: #ef7d00;
  /* Button background to orange */
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  /* Center the button horizontally */
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c;
}

a {
  color: #3c218c;
  text-decoration: none;
}

a:hover {
  color: #ef7d00;
}
</style>