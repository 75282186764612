<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal">
        <div class="modal-header">
          <h3>Выберите изображение</h3>
          <button @click="closeModal" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <!-- Список миниатюр -->
          <div class="thumbnail-container">
            <div
              v-for="image in availableImages"
              :key="image"
              class="thumbnail"
              :class="{ 'thumbnail-selected': selectedImage === image }"
              @click="selectImage(image)"
            >
              <img :src="getImageSrc(image)" :alt="image" class="thumbnail-image">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- Кнопка для загрузки изображения -->
          <!-- <button @click="triggerFileInput" class="btn-green">Загрузить изображение</button>
          <input
            type="file"
            ref="fileInput"
            @change="handleFileUpload"
            accept="image/*"
            style="display: none;"
          /> -->
          <button @click="confirmSelection" class="btn-green">Выбрать</button>
          <button @click="closeModal" class="btn-green">Отмена</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    availableImages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedImage: null,
    };
  },
  methods: {
    getImageSrc(imageUrl) {
      try {
        return require(`@/assets/posters/${imageUrl}`);
      } catch (error) {
        console.error(`Ошибка при загрузке изображения: ${imageUrl}`, error);
        return null;
      }
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    confirmSelection() {
      this.$emit('select', this.selectedImage);
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.modal-header h3 {
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  gap: 10px;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  margin-left: auto;
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  cursor: pointer;
  padding: 8px 16px;
}

.btn-green:hover {
  background: #2a1763;
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transition: border-color 0.3s ease;
}

.thumbnail:hover {
  border-color: #007bff;
}

.thumbnail-selected {
  border-color: #007bff;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>