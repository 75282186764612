<template>
  <navbar />
  <div class="content">
    <div v-if="user" class="user-card">
      <!-- Раздел с информацией о пользователе -->
      <div class="user-info-section">
        <h2>Информация о пользователе</h2>
        <div class="user-info">
          <table>
            <tbody>
              <tr v-for="(value, key) in visibleUserInfo" :key="key">
                <td><i :class="getIcon(key)"></i></td>
                <td>{{ getLabel(key) }}:</td>
                <td>{{ value }}</td>
              </tr>
              <tr v-for="(value, key) in additionalUserInfo" :key="key" v-show="showFullInfo">
                <td><i :class="getIcon(key)"></i></td>
                <td>{{ getLabel(key) }}:</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
          </table>
          <button class="form-btn" @click="toggleFullInfo">
            {{ showFullInfo ? 'Скрыть дополнительную информацию' : 'Показать полную информацию' }}
          </button>
        </div>
      </div>

      <!-- Раздел с кнопками -->
      <h2>Раздел управления</h2>
      <div class="user-actions">
        <!-- История -->
        <div class="history-buttons">
          <h3>История</h3>
          <button class="form-btn" @click="navigateToRegistrationHistory">
            <i class="fas fa-history"></i> История регистраций
          </button>
          <button class="form-btn" @click="navigateToVisitHistory">
            <i class="fas fa-history"></i> История посещений
          </button>
          <button class="form-btn" @click="navigateToCerts">
            <i class="fas fa-award"></i> Дипломы, Сертификаты, Благодарности
          </button>
          <button class="form-btn" @click="navigateToPerformances">
            <i class="fas fa-microphone"></i> Выступления
          </button>
        </div>

        <!-- Управление аккаунтом -->
        <div class="account-management">
          <h3>Управление аккаунтом</h3>
          <button class="form-btn" @click="navigateToFavorite">
            <i class="fa fa-heart"></i> Избранное
          </button>
          <button class="form-btn" @click="navigateToMyEvents">
            <i class="fas fa-calendar-check"></i> Управление мероприятиями
          </button>
          <button class="form-btn" :disabled="isEmailPspu" :class="{ 'disabled-button': isEmailPspu }"
            @click="toggleEditModal">
            <i class="fas fa-edit"></i> Редактировать данные
          </button>
          <EditUserDataModal v-if="showEditModal" :user="user" @close="toggleEditModal" />
          <button class="form-btn" @click="logout">
            <i class="fas fa-sign-out-alt"></i> Выйти из аккаунта
          </button>
        </div>

        <!-- Администрирование -->
        <div class="administration-buttons" v-if="user.security !== 'defaultuser'">
          <h3>Администрирование</h3>
          <button v-if="user.security === 'admin'" class="form-btn" @click="toggleTools('admin')">
            <i class="fas fa-tools"></i> Инструменты администратора
          </button>
          <button v-if="user.security === 'volonter' || user.security === 'admin'" class="form-btn"
            @click="toggleTools('volunteer')">
            <i class="fas fa-tools"></i> Инструменты волонтера
          </button>
        </div>

        <!-- Инструменты -->
        <div class="tools-container" v-if="activeTools">
          <h3>Дополнительные инструменты</h3>
          <button v-if="activeTools === 'admin'" class="form-btn" @click="navigateToReport">
            <i class="fas fa-file-alt"></i> Отчетность
          </button>
          <button v-if="activeTools === 'admin'" class="form-btn" @click="toggleModalAddVolonter">
            <i class="fas fa-person-circle-plus"></i> Добавить волонтера
          </button>
          <ModalAddVolonter v-if="showModalAddVolonter" @close="toggleModalAddVolonter" />
          <button v-if="activeTools === 'admin'" class="form-btn" @click="toggleModalAddAdmin">
            <i class="fas fa-person-circle-plus"></i> Добавить администратора
          </button>
          <ModalAddAdmin v-if="showModalAddAdmin" @close="toggleModalAddAdmin" />
          <button v-if="activeTools === 'admin'" class="form-btn" @click="toggleModalAddEvent">
            <i class="fas fa-calendar-plus"></i> Добавить мероприятия
          </button>
          <button v-if="activeTools === 'volunteer' || activeTools === 'admin'" class="form-btn" @click="openScanner">
            <i class="fas fa-check-to-slot"></i> Сканнер QR-кодов
          </button>
          <qr-scanner-modal v-if="isScannerOpen" @close="closeScanner"></qr-scanner-modal>
          <ModalAddEvent v-if="showModalAddEvent" @close="toggleModalAddEvent" />
        </div>
      </div>
    </div>

    <!-- Сообщение о необходимости авторизации -->
    <div v-else class="auth-required-message">
      <h2>Для просмотра этой страницы необходимо авторизоваться</h2>
      <p>Пожалуйста, войдите в систему, чтобы продолжить.</p>
      <button class="form-btn" @click="goToLogin">
        <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from "@/components/navbar.vue";
import EditUserDataModal from '@/components/EditUserDataModal.vue';
import ModalAddEvent from '@/components/ModalAddEvent.vue';
import ModalAddVolonter from '@/components/ModalAddVolonter.vue';
import ModalAddAdmin from '@/components/ModalAddAdmin.vue';
import QrScannerModal from '@/components/QrScannerModal.vue';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      showCommentsModal: false,
      showEditModal: false,
      showModalAddEvent: false,
      showModalAddVolonter: false,
      showModalAddAdmin: false,
      activeTools: null,
      isScannerOpen: false,
      showFullInfo: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    isEmailPspu() {
      return this.user?.email?.endsWith('@pspu.ru');
    },
    // Фильтруем данные пользователя, чтобы отображать только заполненные поля
    filteredUserInfo() {
      if (!this.user) return {};
      const excludedKeys = ['id', 'uid', 'createdAt', 'updatedAt']; // Поля, которые не нужно отображать
      return Object.fromEntries(
        Object.entries(this.user).filter(([key, value]) =>
          value && !excludedKeys.includes(key)
        )
      );
    },
    visibleUserInfo() {
      const keys = Object.keys(this.filteredUserInfo).slice(0, 4); // Показываем первые 4 поля
      return Object.fromEntries(keys.map(key => [key, this.filteredUserInfo[key]]));
    },
    // Дополнительная информация (остальные поля)
    additionalUserInfo() {
      const keys = Object.keys(this.filteredUserInfo).slice(4); // Показываем остальные поля
      return Object.fromEntries(keys.map(key => [key, this.filteredUserInfo[key]]));
    }
  },
  watch: {
    user: {
      handler(newUser) {
        this.updateUserInfo(newUser);
      },
      deep: true,
    }
  },
  async beforeMount() {
    await this.getUserByUid();
  },
  components: {
    navbar,
    // CommentsModal,
    EditUserDataModal,
    ModalAddEvent,
    ModalAddVolonter,
    ModalAddAdmin,
    // customfooter,
    QrScannerModal
  },
  methods: {
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
    }),
    toggleFullInfo() {
      this.showFullInfo = !this.showFullInfo;
    },
    // Метод для получения иконки по ключу
    getIcon(key) {
      const icons = {
        email: 'fas fa-envelope icon',
        name: 'fas fa-user icon',
        surname: 'fas fa-user icon',
        thirdname: 'fas fa-user icon',
        rolein: 'fas fa-user-tag icon',
        organization: 'fas fa-building icon',
        faculty: 'fas fa-users icon',
        user_group: 'fas fa-users icon',
        country: 'fas fa-globe icon',
        security: 'fa-solid fa-shield-halved icon',
        activitytype: 'fas fa-briefcase icon',
        educationlevel: 'fas fa-graduation-cap icon',
        studydirection: 'fas fa-book icon',
        otheractivitytype: 'fas fa-briefcase icon',
        feddistrict: 'fas fa-map-marker-alt icon',
        region: 'fas fa-map-marker-alt icon',
        city: 'fas fa-map-marker-alt icon',
        phone: 'fas fa-phone icon',
        academicdegree: 'fas fa-graduation-cap icon',
        academictitle: 'fas fa-graduation-cap icon',
        organizationtype: 'fas fa-building icon',
        customorganizationtype: 'fas fa-building icon',
        organizationname: 'fas fa-building icon',
        course: 'fas fa-book-open icon',
      };
      return icons[key] || 'fas fa-info-circle icon'; // Иконка по умолчанию
    },
    // Метод для получения подписи по ключу
    getLabel(key) {
      const labels = {
        email: 'Email',
        name: 'Имя',
        surname: 'Фамилия',
        thirdname: 'Отчество',
        rolein: 'Роль в ПГГПУ',
        organization: 'Организация',
        faculty: 'Факультет',
        user_group: 'Группа',
        country: 'Страна',
        security: 'Статус',
        activitytype: 'Тип деятельности',
        educationlevel: 'Уровень образования',
        studydirection: 'Направление обучения',
        otheractivitytype: 'Другой тип деятельности',
        feddistrict: 'Федеральный округ',
        region: 'Регион',
        city: 'Город',
        phone: 'Телефон',
        academicdegree: 'Ученая степень',
        academictitle: 'Ученое звание',
        organizationtype: 'Тип организации',
        customorganizationtype: 'Тип организации',
        organizationname: 'Название организации',
        course: 'Курс',
      };
      return labels[key] || key; // Подпись по умолчанию
    },
    async reloadUserData() {
      await this.getUserByUid();
    },
    openScanner() {
      this.isScannerOpen = true;
    },
    closeScanner() {
      this.isScannerOpen = false;
    },
    toggleTools(tools) {
      this.activeTools = this.activeTools === tools ? null : tools;
    },
    toggleModalAddAdmin() {
      this.showModalAddAdmin = !this.showModalAddAdmin;
    },
    toggleModalAddVolonter() {
      this.showModalAddVolonter = !this.showModalAddVolonter;
    },
    toggleModalAddEvent() {
      this.showModalAddEvent = !this.showModalAddEvent;
    },
    toggleEditModal() {
      // this.showEditModal = !this.showEditModal;
      Swal.fire({
        icon: 'info',
        title: 'Ошибка',
        text: 'Раздел находится в разработке. Загляните сюда позднее.',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#3c218c',
        customClass: {
          popup: 'custom-swal-popup',
          title: 'custom-swal-title',
          content: 'custom-swal-content',
          confirmButton: 'custom-swal-confirm-button',
        },
      });
    },
    toggleCommentsModal() {
      this.showCommentsModal = !this.showCommentsModal;
    },
    navigateToFavorite() {
      this.$router.push('/favorite');
    },
    navigateToReport() {
      this.$router.push('/report');
    },
    navigateToMyEvents() {
      this.$router.push('/myevents');
    },
    navigateToRegistrationHistory() {
      this.$router.push('/registration-history');
    },
    navigateToVisitHistory() {
      this.$router.push('/visit-history');
    },
    navigateToCerts() {
      // this.$router.push('/user-certs');
      Swal.fire({
        icon: 'info',
        title: 'Ошибка',
        text: 'Раздел находится в разработке. Загляните сюда позднее.',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#3c218c',
        customClass: {
          popup: 'custom-swal-popup',
          title: 'custom-swal-title',
          content: 'custom-swal-content',
          confirmButton: 'custom-swal-confirm-button',
        },
      });
    },
    navigateToPerformances() {
      // this.$router.push('/user-performances');
      Swal.fire({
        icon: 'info',
        title: 'Ошибка',
        text: 'Раздел находится в разработке. Загляните сюда позднее.',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#3c218c',
        customClass: {
          popup: 'custom-swal-popup',
          title: 'custom-swal-title',
          content: 'custom-swal-content',
          confirmButton: 'custom-swal-confirm-button',
        },
      });
    },
    async logout() {
      try {
        localStorage.clear();
        this.$store.commit('user/setUser', null);
        this.$router.push('/');
        Swal.fire({
          icon: 'success', // Иконка "успех"
          title: 'Успех', // Заголовок
          text: 'Вы вышли из аккаунта', // Текст сообщения
          confirmButtonText: 'ОК', // Текст кнопки
          confirmButtonColor: '#3c218c', // Цвет кнопки
          customClass: {
            popup: 'custom-swal-popup', // Класс для контейнера
            title: 'custom-swal-title', // Класс для заголовка
            content: 'custom-swal-content', // Класс для текста
            confirmButton: 'custom-swal-confirm-button', // Класс для кнопки
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    updateUserInfo(newUser) {
      // console.log('Информация о пользователе обновлена:', newUser);
    },
    goToLogin() {
      this.$router.push('/login');
    },
  },
  beforeCreate() {
      window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
table {
  width: 30%;
  /* border-collapse: collapse; */
}

th, td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}



.info-row i {
  vertical-align: middle;
}

.show-more-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.show-more-btn:hover {
  background-color: #ef7d00;
}

.disabled-button {
  background-color: #ccc !important;
  /* Серый цвет для отключенной кнопки */
  color: #888 !important;
  /* Серый текст */
  cursor: not-allowed !important;
  /* Курсор "недоступно" */
  pointer-events: none;
  /* Отключаем события мыши */
}

.auth-required-message {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-required-message h2 {
  color: #3c218c;
  margin-bottom: 10px;
}

.auth-required-message p {
  color: #666;
  margin-bottom: 20px;
}

.auth-required-message .form-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-required-message .form-btn:hover {
  background-color: #ef7d00;
}

.comments-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.user-info {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
}

.info-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.info-row i {
  margin-right: 10px;
}

.info-row .label {
  font-weight: bold;
  margin-right: 5px;
}

.info-row .value {
  font-weight: normal;
}

.icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.value {
  flex-grow: 1;
}

.form-btn {
  background-color: #3c218c;
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
  display: block;
}

.form-btn i {
  margin-right: 10px;
}

.tool-btn i {
  margin-right: 10px;
}

.form-btn:hover {
  background-color: #ef7d00;
}

.content {
  margin-top: 70px;
}

.user-card {
  padding-inline: 70px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 900px) {
  .user-card {
    padding-inline: 0;
  }
}
table {
  width: 10%;
  /* border-collapse: collapse; */
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
}

.user-info p {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.user-info p i {
  margin-right: 10px;
}



a {
  color: #ef7d00;
  text-decoration: none;
}

a:hover {
  color: #3c218c;
}

.user-actions {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  flex-direction: row;
  /* padding: 10px; */
  align-items: first baseline;
}

@media screen and (max-width: 900px) {
  table {
  width: 10%;
  /* border-collapse: collapse; */
}
  .user-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .account-management,
  .administration-buttons,
  .tools-container,
  .history-buttons {
    width: 100%;
    margin: 10px 0;
  }

  .form-btn {
    width: 100%;
    margin: 10px 0;
  }
}

.user-actions button {
  flex: 1 0 calc(33.33% - 20px);
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.account-management,
.administration-buttons,
.tools-container,
.history-buttons {
  margin: 20px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.account-management h3,
.administration-buttons h3,
.tools-container h3,
.history-buttons h3 {
  margin-bottom: 10px;
}

.tool-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.tool-btn.full-width {
  width: 50vw;
  height: auto;
  justify-content: center;
  align-items: center;
}

.tool-btn:hover {
  background-color: #ef7d00;
}

.default-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.default-user-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.default-user-buttons .form-btn {
  flex: 1 0 calc(33.33% - 20px);
  margin: 10px;
}
</style>