<template>
  <button class="scroll-to-top" :class="{ 'show': isVisible }" @click="scrollToTop">
    <i class="fas fa-arrow-up"></i>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false, // Видимость кнопки
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Показывать кнопку, если пользователь прокрутил страницу больше чем на 200px
      this.isVisible = window.scrollY > 50;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Плавная прокрутка
      });
    }
  }
}
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3c218c; /* Цвет как у кнопки "Назад" */
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1001; /* Убедимся, что кнопка поверх других элементов */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9); /* Тень для красоты */
}

.scroll-to-top.show {
  opacity: 1;
}

.scroll-to-top:hover {
  background-color: #ef7d00; /* Цвет при наведении */
}
</style>