<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <h3>Создание документов для {{ event.name }}</h3>
          <button @click="closeModal" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body" id="modalDescription">
          <div v-if="user" class="certificate-generator">
            <!-- Загрузка шаблона -->
            <div class="template-upload">
              <input type="file" @change="handleImageUpload" accept="image/*" class="file-input" />
              <div class="canvas-wrapper">
                <canvas ref="canvas" class="canvas-container"></canvas>
              </div>
              <div class="text-controls">
                <input type="color" v-model="textColor" @change="updateTextStyle" />
                <input type="number" v-model="fontSize" @change="updateTextStyle" placeholder="Размер шрифта" />
                <select v-model="fontFamily" @change="updateTextStyle">
                  <option value="Arial">Arial</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Courier New">Courier New</option>
                </select>
              </div>
            </div>

            <!-- Список присутствующих -->
            <div class="attendance-list">
              <h2>Список присутствующих</h2>
              <button @click="toggleSelectAll" class="form-btn">
                {{ selectAll ? 'Снять выделение' : 'Выбрать всех' }}
              </button>
              <table>
                <thead>
                  <tr>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Отчество</th>
                    <th>Email</th>
                    <th>Выбрать</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="attendance in attendancesWithUserData" :key="attendance.id">
                    <td>{{ attendance.userSurname }}</td>
                    <td>{{ attendance.userName }}</td>
                    <td>{{ attendance.userThirdname }}</td>
                    <td>{{ attendance.userEmail }}</td>
                    <td>
                      <input type="checkbox" v-model="selectedAttendances" :value="attendance" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Создание сертификатов -->
            <div class="certificate-actions">
              <button @click="generateCertificates" class="form-btn">Создать сертификаты</button>
              <button @click="downloadAllCertificates" class="form-btn">Скачать все</button>
            </div>

            <!-- Редактирование и скачивание сертификатов -->
            <div class="certificate-preview" v-if="certificates.length > 0">
              <h2>Созданные сертификаты</h2>
              <div v-for="(certificate, index) in certificates" :key="index" class="certificate-item">
                <div class="canvas-wrapper">
                  <canvas :ref="`certificateCanvas${index}`" class="preview-canvas"></canvas>
                </div>
                <div class="certificate-controls">
                  <button @click="downloadCertificate(index)" class="form-btn">Скачать</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="auth-required-message">
            <h2>Доступ запрещен</h2>
            <p>Для просмотра этой страницы необходимо авторизоваться.</p>
            <button class="form-btn" @click="goToLogin">
              <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as fabric from 'fabric';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Engine, Gender, Case } from 'russian-nouns-js'; // Импортируем библиотеку

const rne = new Engine(); // Создаем экземпляр движка для склонения

export default {
  name: 'CertificateGenerator',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canvas: null,
      backgroundImage: null,
      selectedObject: null,
      originalImageWidth: 0,
      originalImageHeight: 0,
      selectedAttendances: [], // Выбранные пользователи
      certificates: [], // Созданные сертификаты
      selectAll: false, // Флаг для выбора всех
      textColor: '#000000', // Цвет текста
      fontSize: 30, // Размер шрифта
      fontFamily: 'Arial', // Шрифт
      textObject: null, // Текстовое поле для ФИО
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('attendance', ['attendances']),
    ...mapGetters('user', ['users']),
    attendancesWithUserData() {
      return this.attendances.map(attendance => {
        const user = this.users.find(u => u.uid === attendance.useruid);
        return {
          ...attendance,
          userSurname: user ? user.surname || '' : '',
          userName: user ? user.name || '' : '',
          userThirdname: user ? user.thirdname || '' : '',
          userEmail: user ? user.email || '' : '',
        };
      });
    },
  },
  methods: {
    ...mapActions('attendance', ['getAttendanceByEventUid']),
    ...mapActions('user', ['getAllUsers']),
    initCanvas(width, height) {
      if (this.canvas) {
        this.canvas.dispose(); // Очищаем предыдущий канвас
      }
      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        width: width,
        height: height,
      });
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const modalWidth = this.$el.querySelector('.modal').clientWidth;
            const scaleFactor = modalWidth / (2 * img.width);
            const scaledHeight = img.height * scaleFactor;

            this.originalImageWidth = img.width; // Сохраняем оригинальные размеры
            this.originalImageHeight = img.height;

            this.initCanvas(this.originalImageWidth, this.originalImageHeight);
            const fabricImage = new fabric.Image(img, {
              left: 0,
              top: 0,
              scaleX: 1, // Оригинальный масштаб
              scaleY: 1, // Оригинальный масштаб
              selectable: false,
              evented: false,
            });
            this.setBackgroundImage(fabricImage);
            this.addNameField();
          };
        };
        reader.readAsDataURL(file);
      }
    },
    setBackgroundImage(img) {
      if (this.backgroundImage) {
        this.canvas.remove(this.backgroundImage);
      }
      this.backgroundImage = img;
      this.canvas.add(img);
      this.canvas.requestRenderAll();
    },
    addNameField() {
      if (this.textObject) {
        this.canvas.remove(this.textObject);
      }
      this.textObject = new fabric.Textbox('{Фамилия Имя Отчество}', {
        left: 100,
        top: 100,
        fontSize: this.fontSize,
        fontFamily: this.fontFamily,
        fill: this.textColor,
        width: this.originalImageWidth, // Оставляем отступы по бокам
        hasControls: true,
        hasBorders: true,
        editable: false,
        selectable: true,
        splitByGrapheme: false, // Запрещаем перенос текста
      });
      this.canvas.add(this.textObject);
      this.canvas.requestRenderAll();
    },
    updateTextStyle() {
      if (this.textObject) {
        this.textObject.set({
          fill: this.textColor,
          fontSize: this.fontSize,
          fontFamily: this.fontFamily,
        });
        this.canvas.requestRenderAll();
      }
    },
    // Функция для склонения ФИО в дательный падеж
// Функция для склонения ФИО в дательный падеж
async getFullNameInDativeCase(surname, name, patronymic) {
  try {
    const fullName = `${surname || ''} ${name || ''} ${patronymic || ''}`.trim();
    if (!fullName) return '';

    // Определяем род имени
    let nameGender = Gender.MASCULINE; // По умолчанию мужской род
    const lastLetter = (name || '').slice(-1).toLowerCase();
    if (lastLetter === 'а' || lastLetter === 'я') {
      nameGender = Gender.FEMININE;
    }

    // Определяем род отчества
    let patronymicGender = Gender.MASCULINE; // По умолчанию мужской род
    const patronymicLastLetter = (patronymic || '').slice(-1).toLowerCase();
    if (patronymicLastLetter === 'а' || patronymicLastLetter === 'я') {
      patronymicGender = Gender.FEMININE;
    }

    // Склоняем фамилию, имя и отчество
    const declinedSurname = rne.decline({ text: surname, gender: nameGender }, Case.DATIVE)[0] || surname;
    const declinedName = rne.decline({ text: name, gender: nameGender }, Case.DATIVE)[0] || name;
    const declinedPatronymic = rne.decline({ text: patronymic, gender: patronymicGender }, Case.DATIVE)[0] || patronymic;

    // Собираем склонённое ФИО
    return `${declinedSurname} ${declinedName} ${declinedPatronymic}`.trim();
  } catch (error) {
    console.error('Ошибка при склонении ФИО:', error);
    return `${surname || ''} ${name || ''} ${patronymic || ''}`.trim(); // Возвращаем оригинальное ФИО в случае ошибки
  }
},
async generateCertificates() {
  // Очищаем предыдущие сертификаты
  this.certificates = [];
  this.$nextTick(async () => {
    for (const attendance of this.selectedAttendances) {
      // Проверяем, что все поля ФИО определены
      const surname = attendance.userSurname || '';
      const name = attendance.userName || '';
      const patronymic = attendance.userThirdname || '';

      // Склоняем ФИО в дательный падеж
      const fullNameInDative = await this.getFullNameInDativeCase(surname, name, patronymic);

      this.certificates.push({
        name: fullNameInDative || '', // Используем ФИО в дательном падеже или пустую строку
        canvas: null,
      });
    }

    this.$nextTick(() => {
      this.certificates.forEach((certificate, index) => {
        const canvasElement = this.$refs[`certificateCanvas${index}`][0];
        const canvas = new fabric.Canvas(canvasElement, {
          width: this.originalImageWidth,
          height: this.originalImageHeight,
        });

        // Создаем изображение с оригинальными размерами
        const fabricImage = new fabric.Image(this.backgroundImage.getElement(), {
          left: 0,
          top: 0,
          scaleX: 1, // Оригинальный масштаб
          scaleY: 1, // Оригинальный масштаб
          selectable: false,
          evented: false,
        });
        canvas.add(fabricImage);

        // Добавляем текстовое поле с теми же параметрами, что и в основном шаблоне
        const text = new fabric.Textbox(certificate.name || '', { // Убедимся, что текст не undefined
          left: this.textObject.left,
          top: this.textObject.top,
          fontSize: this.fontSize,
          fontFamily: this.fontFamily,
          fill: this.textColor,
          width: this.textObject.width, // Фиксируем ширину
          splitByGrapheme: false, // Запрещаем перенос текста
        });
        canvas.add(text);

        certificate.canvas = canvas;
      });
    });
  });
},
    downloadCertificate(index) {
      const certificate = this.certificates[index];
      const dataURL = certificate.canvas.toDataURL({
        format: 'png',
        quality: 1,
        multiplier: 1, // Используем оригинальное качество
      });
      const link = document.createElement('a');
      link.href = dataURL;
      const eventName = this.event.name.substring(0, 5).replace(/\s+/g, '_');
      const userName = certificate.name.replace(/\s+/g, '_');
      link.download = `Сертификат_${userName}_${eventName}.png`;
      link.click();
    },
    downloadAllCertificates() {
      this.certificates.forEach((certificate, index) => {
        this.downloadCertificate(index);
      });
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      this.selectedAttendances = this.selectAll ? [...this.attendancesWithUserData] : [];
    },
    closeModal() {
      this.$emit('close');
    },
    goToLogin() {
      this.$router.push('/login');
    },
  },
  async created() {
    await this.getAllUsers();
    await this.getAttendanceByEventUid(this.event.uid);
  },
};
</script>

<style scoped>
.certificate-generator {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.template-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.canvas-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.canvas-container,
.preview-canvas {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.attendance-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.attendance-list th,
.attendance-list td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.attendance-list th {
  background-color: #3c218c;
  color: white;
}

.certificate-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.certificate-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.preview-canvas {
  border: 1px solid #000;
}

.auth-required-message {
  text-align: center;
  padding: 20px;
}

.auth-required-message h2 {
  color: #ff0000;
}

.form-btn {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.form-btn:hover {
  background-color: #ef7d00;
}

.file-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f8f8f8;
  cursor: pointer;
}

.file-input::-webkit-file-upload-button {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  margin-left: auto;
}
</style>