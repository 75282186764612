import instance from "@/middlewares";
import axios from 'axios';

export default {
  name: 'user',
  state: () => ({
    user: null,
    favoriteEvents: [],
    users: [] // Добавляем состояние для хранения всех пользователей
  }),
  getters: {
    user: state => state.user,
    users: state => state.users // Геттер для получения всех пользователей
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    ADD_FAVORITE_EVENT(state, event) {
      state.favoriteEvents.push(event);
    },
    SET_FAVORITE_EVENTS(state, events) {
      state.favoriteEvents = Array.isArray(events) ? events : [];
    },
    REMOVE_FAVORITE_EVENT(state, event) {
      state.favoriteEvents = state.favoriteEvents.filter(e => e.uid !== event.uid);
    },
    SET_USERS(state, users) { 
      state.users = users;
    }
  },
  actions: {
    async getUserByUid({ commit }) {
      const uid = localStorage.getItem('uid');
      if (!uid) {
        return commit('setUser', null);
      }

      try {
        const user = await instance.get(`/api/users/${uid}`);
        if (user) {
          return commit('setUser', user.data);
        } else {
          console.log(user.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateUser({ commit }, userData) {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.put(`${process.env.VUE_APP_SERVER}/api/users/${userData.uid}`, userData, {
          headers: {
            'Content-type': 'application/json; charset=utf-8',
            'x-access-token': token
          }
        });
        commit('setUser', response.data);
        return response.data;
      } catch (error) {
        console.error('Ошибка при обновлении пользователя:', error);
        throw error;
      }
    },
    async addToFavorites({ commit, state }, event) {
      const token = localStorage.getItem('accessToken');
      const user = state.user;
      if (!token || !user) {
        console.error('No token or user found');
        return;
      }
      try {
        const response = await axios.post(`${process.env.VUE_APP_SERVER}/api/users/${user.uid}/favorites`, {
          eventUid: event.uid
        }, {
          headers: {
            'Content-type': 'application/json; charset=utf-8',
            'x-access-token': token
          }
        });
        const isFavorite = state.favoriteEvents.some(e => e.uid === event.uid);
        if (isFavorite) {
          commit('REMOVE_FAVORITE_EVENT', event);
        } else {
          commit('ADD_FAVORITE_EVENT', event);
        }
      } catch (error) {
        console.error('Ошибка при добавлении/удалении мероприятия в избранное:', error);
      }
    },
    async getFavoriteEvents({ commit }) {
      const token = localStorage.getItem('accessToken');
      const user = localStorage.getItem('uid');
    
      // Проверка наличия токена и пользователя
      if (!token || !user) {
        console.error('No token or user found');
        return { success: false, message: 'No token or user found', data: [] };
      }
    
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER}/api/users/${user}/favorites`, {
          headers: {
            'Content-type': 'application/json; charset=utf-8',
            'x-access-token': token
          }
        });
    
        // Если ответ содержит данные, обновляем состояние
        if (response.data && Array.isArray(response.data.data)) {
          commit('SET_FAVORITE_EVENTS', response.data.data);
          return { success: true, message: response.data.message || 'Favorite events retrieved successfully', data: response.data.data };
        } else {
          // Если данные отсутствуют, возвращаем пустой массив
          commit('SET_FAVORITE_EVENTS', []);
          return { success: false, message: 'No favorite events found', data: [] };
        }
      } catch (error) {
        console.error('Ошибка при получении избранного:', error);
        return { success: false, message: 'Error retrieving favorite events', data: [] };
      }
    },

    async getUserByUidForReport({ commit }, useruid) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER}/api/users/report/${useruid}`, {
          headers: {
            'Content-type': 'application/json; charset=utf-8',
            'x-access-token': localStorage.getItem('accessToken')
          }
        });
        return response.data;
      } catch (error) {
        console.error('Ошибка при получении информации о пользователе для отчета:', error);
        throw error;
      }
    },
    async getAllUsers({ commit }) { // Новое действие для получения всех пользователей
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER}/api/users`, {
          headers: {
            'Content-type': 'application/json; charset=utf-8',
            'x-access-token': localStorage.getItem('accessToken')
          }
        });
        commit('SET_USERS', response.data);
      } catch (error) {
        console.error('Ошибка при получении списка пользователей:', error);
        throw error;
      }
    }
  },
  namespaced: true
};