<template>
  <nav class="main-nav">
    <div class="left-part">
      <img src="../assets/NavBarLogo.png" alt="Logo" class="logo circle" @click="goToEventsView">
    </div>
    <div class="center-part">
      <h2>{{ currentRouteName }}</h2>
    </div>
    <div class="right-part">
      <button class="burger-button" @click="toggleMenu">
        <i class="fas fa-bars"></i>
      </button>
      <div class="dropdown-menu" :class="{ 'show-menu': isMenuOpen }">
        <!-- Для авторизованных пользователей -->
        <template v-if="user">
          <router-link to="/EventsView" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/EventsView' }">
            <i class="fas fa-calendar-alt"></i>
            <span class="button-label">Мероприятия</span>
          </router-link>
          <router-link to="/favorite" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/favorite' }">
            <i class="fas fa-heart"></i>
            <span class="button-label">Избранное</span>
          </router-link>
          <router-link to="/about" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/about' }">
            <i class="fas fa-user"></i>
            <span class="button-label">Личный кабинет</span>
          </router-link>
          <button class="dropdown-button" @click="logout">
            <i class="fas fa-sign-out-alt"></i>
            <span class="button-label">Выйти</span>
          </button>
        </template>
        <!-- Для неавторизованных пользователей -->
        <template v-else>
          <router-link to="/EventsView" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/EventsView' }">
            <i class="fas fa-calendar-alt"></i>
            <span class="button-label">Мероприятия</span>
          </router-link>
          <router-link to="/login" class="dropdown-button">
            <i class="fas fa-sign-in-alt"></i>
            <span class="button-label">Войти</span>
          </router-link>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import router from '@/router';
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      isMenuOpen: false,
      routeNames: {
        '/registration-history': 'История регистраций',
        '/visit-history': 'История посещений',
        '/login': 'Авторизация',
        '/about': 'Личный кабинет',
        '/EventsView': 'Мероприятия',
        '/register': 'Регистрация',
        '/DataAddforMS': 'Дополнительные данные',
        '/event/:id': 'Детали мероприятия',
        '/favorite': 'Избранное',
        '/report': 'Отчеты',
        '/myevents': 'Управление мероприятиями',
        '/registration-report/:eventId': 'Отчет по регистрациям',
        '/attendance-report/:eventId': 'Отчет по посещениям',
        '/user-certs':'Сертификаты',
        '/user-performances':'Выступления',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    currentRouteName() {
      const path = this.$route.path;

      if (this.routeNames[path]) {
        return this.routeNames[path];
      }

      for (const routePath in this.routeNames) {
        if (path.startsWith(routePath.split(':')[0])) {
          return this.routeNames[routePath];
        }
      }

      return '';
    },
  },
  methods: {
    goToEventsView() {
      this.$router.push('/EventsView');
    },
    // ...mapActions('user', ['logoutAction']),
    async logout() {
      try {
        // await this.logoutAction();
        localStorage.clear();
        this.$store.commit('user/setUser', null);
        this.$router.push('/');
        Swal.fire({
        icon: 'success', // Иконка "успех"
        title: 'Успех', // Заголовок
        text: 'Вы вышли из аккаунта', // Текст сообщения
        confirmButtonText: 'ОК', // Текст кнопки
        confirmButtonColor: '#3c218c', // Цвет кнопки
        customClass: {
          popup: 'custom-swal-popup', // Класс для контейнера
          title: 'custom-swal-title', // Класс для заголовка
          content: 'custom-swal-content', // Класс для текста
          confirmButton: 'custom-swal-confirm-button', // Класс для кнопки
        },
      });
      } catch (error) {
        console.error(error);
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
}
</script>



<style scoped>
.main-nav {
  position: fixed; /* Закрепляем навбар */
  top: 0; /* Прижимаем к верхнему краю */
  left: 0;
  right: 0;
  z-index: 1000; /* Убедимся, что навбар поверх других элементов */
  background-color: #3c218c;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.circle {
  border-radius: 50%;
  width: 100%;
  height: auto;
}

.left-part {
  display: flex;
  align-items: center;
  max-width: 10%;
  max-height: auto;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.5s ease;
  margin-left: 30px;
  border-radius: 20px;
  box-sizing: border-box;
}

.logo {
  max-width: 50%;
  max-height: auto;
  background-color: aliceblue;
  cursor: pointer;
  box-sizing: border-box;
}

.center-part {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; /* Фиксированное позиционирование относительно окна браузера */
  top: 50%; /* Сдвигаем на 50% от верхнего края */
  left: 50%; /* Сдвигаем на 50% от левого края */
  transform: translate(-50%, -50%); /* Смещаем элемент на половину его ширины и высоты */
  width: 30%;
  box-sizing: border-box;
}

.right-part {
  display: flex;
  /* padding-right: 30px;
  margin-right: 30px; */
  box-sizing: border-box;
}

.burger-button {
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 20px;
}

.dropdown-menu {
  display: flex;
  align-items: center;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  border-style: hidden;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 0 0 1px white;
  width: 30px;
  height: 30px;
  background-color: #3c218c;
  box-sizing: border-box;
}

.dropdown-button:hover {
  box-shadow: 0 0 0 1px #ef7d00;
  color: #ef7d00;
  background-color: #3c218c;
}

.active-button {
  color: #ef7d00;
  box-shadow: 0 0 0 1px #ef7d00;
}

h2 {
  color: aliceblue;
}

@media screen and (max-width: 768px) {
  .main-nav {
    padding: 0;
    justify-content: space-between;
    width: 100%;
  }
  .right-part {
  display: flex;
  box-sizing: border-box;
}
  .left-part {
    display: none;
  }

  .center-part {
    width: auto;
    /* margin-left: 20px; */
  }

  .burger-button {
    display: flex;
    margin-left: 20px;
  }

  .dropdown-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #3c218c;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-menu.show-menu {
    display: flex;
  }

  .dropdown-button {
    margin: 10px 0;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    justify-content: flex-start;
    padding-left: 20px;
  }

  .button-label {
    margin-left: 10px;
    color: white;
  }
}

@media screen and (min-width: 769px) {
  .button-label {
    display: none;
  }
}
</style>