<template>
  <div v-if="event" class="event-card" :class="{ 'past-event': isEventPast }">
    <div class="event-image-container" @click="goToEventDetails">
      <img v-if="event.imagename" :src="getImageSrc(event.imagename)" alt="Event Image" class="event-image" />
      <div v-else class="no-image">Изображение отсутствует</div>
    </div>
    <div class="event-details" @click="goToEventDetails">
      <h2 class="event-name">{{ event.name }}</h2>
      <p class="event-date">{{ formatDate(event.datestart, event.dateend, event.monthstart, event.yearstart) }}</p>
      <p class="event-time">{{ formatTime(event.timestart, event.timeend) }}</p>
    </div>
    <div class="event-actions">
      <button class="text-button" @click="editEventData">
        <i class="fas fa-edit"></i> Редактировать данные о мероприятии
      </button>
      <button class="text-button" @click="toggleModalAddVolonterForEvent">
        <i class="fas fa-users"></i> Назначить волонтеров
      </button>
      <ModalAddVolonterForEvent v-if="showModalAddVolonterForEvent" @close="toggleModalAddVolonterForEvent" />
      <button class="text-button" @click="openRegistrationReport">
        <i class="fas fa-file-alt"></i> Отчет о регистрации
      </button>
      <button class="text-button" @click="openAttendanceReport">
        <i class="fas fa-clipboard-check"></i> Отчет о посещении
      </button>
      <button class="text-button" @click="openFullReport">
        <i class="fas fa-clipboard-check"></i> Полный отчет (Регистрация/посещение)
      </button>
      <button class="text-button" @click="toggleGiveCerts">
        <i class="fas fa-certificate"></i> Выдача документов
      </button>
      <ModalGiveCerts v-if="showModalGiveCerts" :event="event" @close="toggleGiveCerts" />
    </div>
  </div>
  <div v-else>
    Loading event data...
  </div>
  <ModalEditEvent v-if="showEditModal" :event="event" @close="showEditModal = false" @save="saveEvent" />
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';
import moment from 'moment';
import 'moment/locale/ru';
import ModalEditEvent from '@/components/ModalEditEvent.vue';
import ModalAddVolonterForEvent from './ModalAddVolonterForEvent.vue';
import ModalGiveCerts from './ModalGiveCerts.vue';
import Swal from 'sweetalert2';
export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showEditModal: false,
      showModalAddVolonterForEvent: false,
      showModalGiveCerts: false,
    };
  },
  components: {
    ModalEditEvent,
    ModalAddVolonterForEvent,
    ModalGiveCerts
  },
  computed: {

    isEventPast() {
      if (this.event.datestart && this.event.timestart) {
        const eventDateTime = moment(`${this.event.datestart} ${this.event.timestart}`, 'YYYY-MM-DD HH:mm:ss');
        return eventDateTime.isBefore(moment(), 'day');
      } else if (this.event.monthstart && this.event.yearstart) {
        const eventDate = moment().month(this.event.monthstart - 1).year(this.event.yearstart);
        return eventDate.isBefore(moment(), 'month');
      }
      return false;
    }
  },
  methods: {
    formatDate(dateStart, dateEnd, monthStart, yearStart) {
      if (!dateStart && !dateEnd) {
        const monthNames = [
          "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
          "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];
        return `Дата проведения на уточнении (${monthNames[monthStart - 1]} ${yearStart})`;
      } else {
        const startDate = moment(dateStart, 'YYYY-MM-DD');
        const endDate = moment(dateEnd, 'YYYY-MM-DD');
        return `${startDate.format('DD.MM.YY')} - ${endDate.format('DD.MM.YY')}`;
      }
    },
    toggleModalAddVolonterForEvent() {
      // this.showModalAddVolonterForEvent = !this.showModalAddVolonterForEvent;
      Swal.fire({
        icon: 'info',
        title: 'Ошибка',
        text: 'Раздел временно недоступен. Обратитесь за помощью к администратору',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#3c218c',
        customClass: {
          popup: 'custom-swal-popup',
          title: 'custom-swal-title',
          content: 'custom-swal-content',
          confirmButton: 'custom-swal-confirm-button',
        },
      });
    },
    toggleGiveCerts() {
      this.showModalGiveCerts = !this.showModalGiveCerts;
    },
    formatTime(timeStart, timeEnd) {
      if (!timeStart && !timeEnd) {
        return "Время на уточнении";
      } else {
        return `${moment(timeStart, 'HH:mm:ss').format('HH:mm')} - ${moment(timeEnd, 'HH:mm:ss').format('HH:mm')}`;
      }
    },
    goToEventDetails() {
      this.$router.push({ name: 'EventDetails', params: { id: this.event.uid } });
    },
    getImageSrc(imageUrl) {
      try {
        return require(`@/assets/posters/${imageUrl}`);
      } catch (error) {
        console.error(`Ошибка при загрузке изображения: ${imageUrl}`, error);
        return null;
      }
    },
    editEventData() {
      this.showEditModal = true;
    },
    openRegistrationReport() {
      const encodedEventName = encodeURIComponent(this.event.name); // Кодируем название мероприятия
      this.$router.push({
        name: 'RegistrationReport',
        params: {
          eventId: this.event.uid,
          eventName: encodedEventName // Передаем закодированное название
        }
      });
    },
    openAttendanceReport() {
      const encodedEventName = encodeURIComponent(this.event.name); // Кодируем название мероприятия
      this.$router.push({
        name: 'AttendanceReport',
        params: {
          eventId: this.event.uid,
          eventName: encodedEventName // Передаем закодированное название
        }
      });
    },
    openFullReport() {
      const encodedEventName = encodeURIComponent(this.event.name); // Кодируем название мероприятия
      this.$router.push({
        name: 'FullReport',
        params: {
          eventId: this.event.uid,
          eventName: encodedEventName // Передаем закодированное название
        }
      });
    },
    saveEvent() {
      this.showEditModal = false;
      // Логика для обновления данных в базе данных
    }
  }
};
</script>

<style scoped>
.past-event .event-image {
  opacity: 0.5;
}

.event-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.event-image-container {
  height: 150px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.event-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.event-card:hover .event-image-container .event-image {
  transform: scale(1.1);
}

.event-details {
  background-color: #f5f5f5;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  text-align: center;
}

.event-name {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
}

.event-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}

.text-button {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  /* Отступ между иконкой и текстом */
}

.text-button:hover {
  background-color: #ef7d00;
}

.text-button i {
  font-size: 16px;
  /* Размер иконки */
}

@media screen and (max-width: 600px) {
  .event-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .event-image-container {
    height: 200px;
  }

  .event-image {
    height: 200px;
  }

  .event-name {
    font-size: 18px;
  }

  .event-date {
    font-size: 16px;
  }

  .event-time {
    font-size: 16px;
  }

  .event-actions {
    margin: 10px;
  }

  .text-button {
    padding: 12px 24px;
  }
}
</style>