import router from '@/router';
import instance from "@/middlewares";
import Swal from 'sweetalert2';
const checkStatuses = (status) => {
    switch (status) {
        case 400:
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Проблема на клиенте?',
                confirmButtonText: 'ОК',
                confirmButtonColor: '#3c218c',
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    content: 'custom-swal-content',
                    confirmButton: 'custom-swal-confirm-button',
                },
            });
            return false;

        case 404:
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Пользователь не найден',
                confirmButtonText: 'ОК',
                confirmButtonColor: '#3c218c',
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    content: 'custom-swal-content',
                    confirmButton: 'custom-swal-confirm-button',
                },
            });
            return false;

        case 414:
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Пароль неверный',
                confirmButtonText: 'ОК',
                confirmButtonColor: '#3c218c',
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    content: 'custom-swal-content',
                    confirmButton: 'custom-swal-confirm-button',
                },
            });
            return false;

        case 405:
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Пользователь с таким Email уже существует. Выполните вход.',
                confirmButtonText: 'ОК',
                confirmButtonColor: '#3c218c',
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    content: 'custom-swal-content',
                    confirmButton: 'custom-swal-confirm-button',
                },
            });
            return false;

        case 403:
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Не авторизован',
                confirmButtonText: 'ОК',
                confirmButtonColor: '#3c218c',
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    content: 'custom-swal-content',
                    confirmButton: 'custom-swal-confirm-button',
                },
            });
            return false;

        case 500:
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Проблема на сервере',
                confirmButtonText: 'ОК',
                confirmButtonColor: '#3c218c',
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    content: 'custom-swal-content',
                    confirmButton: 'custom-swal-confirm-button',
                },
            });
            return false;

        case 401:
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Проблема с токеном',
                confirmButtonText: 'ОК',
                confirmButtonColor: '#3c218c',
                customClass: {
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title',
                    content: 'custom-swal-content',
                    confirmButton: 'custom-swal-confirm-button',
                },
            });
            return false;

        default:
            return true;
    }
};

export default {
    name: 'auth',
    state: () => ({
        isAuth: false
    }),
    getters: {},
    mutations: {
        setAuth(state, isAuth) {
            state.isAuth = isAuth;
        }
    },
    actions: {
        async register({ }, {
            email,
            password,
            surname,
            name,
            thirdname,
            organization,
            faculty,
            user_group,
            country,
            authType,
            activitytype,
            educationlevel,
            course,
            studydirection,
            otheractivitytype,
            feddistrict,
            region,
            city,
            phone,
            academicdegree,
            academictitle,
            organizationtype,
            customorganizationtype,
            organizationname
        }) {
            try {
                const response = await instance.post('/api/auth/signup', {
                    email,
                    password,
                    surname,
                    name,
                    thirdname,
                    organization,
                    faculty,
                    user_group,
                    country,
                    authType,
                    activitytype,
                    educationlevel,
                    course,
                    studydirection,
                    otheractivitytype,
                    feddistrict,
                    region,
                    city,
                    phone,
                    academicdegree,
                    academictitle,
                    organizationtype,
                    customorganizationtype,
                    organizationname,
                    security: 'defaultuser', // Значение по умолчанию
                    rolein: 'Гость', // Значение по умолчанию
                });

                if (response.status === 201) {
                    // window.alert('Вы успешно зарегистрировались!');
                    router.push('/');
                } else {
                    throw new Error('Ошибка регистрации: ' + response.data.message);
                }
            } catch (error) {
                throw error;
            }
        },
        async login({ commit }, { email, password }) {
            try {
                const response = await instance.post('/api/auth/signin', {
                    email,
                    password
                });

                // Если статус 200, то вход успешен
                if (response.status === 200) {
                    const result = response.data;
                    commit('setAuth', true);
                    localStorage.setItem('accessToken', result.accessToken);
                    localStorage.setItem('refreshToken', result.refreshToken);
                    localStorage.setItem('uid', result.uid);
                    router.push('/EventsView');
                } else {
                    // Если статус не 200, выбрасываем ошибку
                    throw new Error('Ошибка входа: ' + response.data.message);
                }
            } catch (error) {
                // Обработка ошибок
                if (error.response) {
                    if (error.response.status === 404) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ошибка',
                            text: 'Пользователь с таким email не найден.',
                            confirmButtonText: 'ОК',
                            confirmButtonColor: '#3c218c',
                            customClass: {
                                popup: 'custom-swal-popup',
                                title: 'custom-swal-title',
                                content: 'custom-swal-content',
                                confirmButton: 'custom-swal-confirm-button',
                            },
                        });
                    } else if (error.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ошибка',
                            text: 'Неверный пароль.',
                            confirmButtonText: 'ОК',
                            confirmButtonColor: '#3c218c',
                            customClass: {
                                popup: 'custom-swal-popup',
                                title: 'custom-swal-title',
                                content: 'custom-swal-content',
                                confirmButton: 'custom-swal-confirm-button',
                            },
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ошибка',
                            text: 'Ошибка входа: ' + error.response.data.message,
                            confirmButtonText: 'ОК',
                            confirmButtonColor: '#3c218c',
                            customClass: {
                                popup: 'custom-swal-popup',
                                title: 'custom-swal-title',
                                content: 'custom-swal-content',
                                confirmButton: 'custom-swal-confirm-button',
                            },
                        });
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: 'Ошибка входа: ' + error.message,
                        confirmButtonText: 'ОК',
                        confirmButtonColor: '#3c218c',
                        customClass: {
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            content: 'custom-swal-content',
                            confirmButton: 'custom-swal-confirm-button',
                        },
                    });
                }

                // Возвращаем false, чтобы указать, что вход не удался
                return false;
            }
        },
        async changeAccess({ }) {
            try {
                const response = await instance.post('/api/auth/changeAccess', {}, {
                    headers: {
                        'x-refresh-token': localStorage.getItem('refreshToken')
                    }
                });

                if (!checkStatuses(response.status)) return;

                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);
            } catch (error) {
                console.error('Change access error:', error.response ? error.response.data : error.message);
            }
        }
    },
    namespaced: true
};