<template>
    <navbar2 />
    <div class="content">

        <h2>Регистрация</h2>
        <div class="home">

            <form @submit.prevent="go_register()">
                <p>
                    Если Вы являетесь сотрудником/преподавателем/студентом ПГГПУ, то вам не нужно регистрироваться.
                    Используйте корпоративные данные для входа на странице входа.
                </p>
                <div class="required-note">
                    <span class="required">*</span> — обязательные поля для заполнения
                </div>
                <!-- Email -->
                <div class="form-group">
                    <label for="email">Email <span class="required">*</span></label>
                    <input id="email" type="email" class="form-input" placeholder="Введите email" required minlength="5"
                        maxlength="40" v-model="formData.email" @input="checkEmailDomain" />
                </div>

                <!-- Подсказка для корпоративных email -->
                <div v-if="isCorporateEmail" class="corporate-email-warning">
                    <p>Вы используете корпоративный email. Пожалуйста, перейдите на страницу входа и войдите с помощью
                        корпоративных данных.</p>
                </div>

                <!-- Пароль -->
                <div class="form-group">
                    <label for="password">Пароль <span class="required">*</span></label>
                    <div class="password-input">
                        <input id="password" :type="passwordFieldType" class="form-input" placeholder="Введите пароль"
                            required minlength="8" maxlength="40" v-model="formData.password"
                            :disabled="isCorporateEmail" />
                        <button type="button" class="eye-button" @click="togglePasswordVisibility"
                            :disabled="isCorporateEmail">
                            <span v-if="passwordFieldType === 'password'">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span v-else>
                                <i class="fas fa-eye-slash"></i>
                            </span>
                        </button>
                    </div>
                    <div class="password-hint">Пароль должен быть не короче 8 символов</div>
                    <!-- Шкала надежности пароля -->
                    <div class="password-strength">
                        <div :class="['strength-bar', `strength-${passwordStrength}`]"></div>
                        <div class="strength-status">{{ passwordStatus }}</div>
                    </div>
                </div>

                <!-- Подтверждение пароля -->
                <div class="form-group">
                    <label for="confirmPassword">Подтверждение пароля <span class="required">*</span></label>
                    <div class="password-input">
                        <input id="confirmPassword" :type="confirmPasswordFieldType" class="form-input"
                            placeholder="Подтвердите пароль" required minlength="8" maxlength="40"
                            v-model="formData.confirmPassword" :disabled="isCorporateEmail" />
                        <button type="button" class="eye-button" @click="toggleConfirmPasswordVisibility"
                            :disabled="isCorporateEmail">
                            <span v-if="confirmPasswordFieldType === 'password'">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span v-else>
                                <i class="fas fa-eye-slash"></i>
                            </span>
                        </button>
                    </div>
                </div>

                <!-- Остальные поля формы -->
                <!-- Фамилия -->
                <div class="form-group">
                    <label for="surname">Фамилия <span class="required">*</span></label>
                    <input id="surname" type="text" class="form-input" placeholder="Введите фамилию" required
                        v-model="formData.surname" :disabled="isCorporateEmail" />
                </div>

                <!-- Имя -->
                <div class="form-group">
                    <label for="name">Имя <span class="required">*</span></label>
                    <input id="name" type="text" class="form-input" placeholder="Введите имя" required
                        v-model="formData.name" :disabled="isCorporateEmail" />
                </div>

                <!-- Отчество -->
                <div class="form-group">
                    <label for="thirdname">Отчество</label>
                    <input id="thirdname" type="text" class="form-input" placeholder="Введите отчество"
                        v-model="formData.thirdname" :disabled="isCorporateEmail" />
                </div>

                <!-- Тип организации -->
                <div class="form-group">
                    <label for="organizationType">Тип организации <span class="required">*</span></label>
                    <select id="organizationType" name="organizationType" class="form-input" :required="true"
                        v-model="formData.organizationtype" @change="handleOrganizationTypeChange"
                        :disabled="isCorporateEmail">
                        <option value="" disabled>Выберите...</option>
                        <option v-for="type in organizationTypes" :key="type.id" :value="type.id">{{ type.name }}
                        </option>
                    </select>
                </div>

                <!-- Укажите тип организации -->
                <div v-if="showOrganizationTypeInput" class="form-group">
                    <label for="customOrganizationType">Укажите тип организации <span class="required">*</span></label>
                    <input type="text" id="customOrganizationType" name="customOrganizationType" class="form-input"
                        placeholder="Укажите тип организации" :required="showOrganizationTypeInput"
                        v-model="formData.customorganizationtype" :disabled="isCorporateEmail">
                </div>

                <!-- Полное наименование организации -->
                <div class="form-row">
                    <div class="form-group">
                        <label for="organizationName">Полное наименование организации <span
                                class="required">*</span></label>
                        <input type="text" id="organizationName" name="organizationName" class="form-input"
                            placeholder="Наименование организации" :required="true" v-model="formData.organizationname"
                            :disabled="isCorporateEmail">
                    </div>
                </div>

                <!-- Вид деятельности в организации -->
                <div class="form-group">
                    <label for="activityType">Вид деятельности в организации <span class="required">*</span></label>
                    <select id="activityType" name="activityType" class="form-input" :required="true"
                        v-model="formData.activitytype" @change="handleActivityTypeChange" :disabled="isCorporateEmail">
                        <option value="" disabled>Выберите...</option>
                        <option value="Студент">Студент</option>
                        <option value="Преподаватель">Преподаватель</option>
                        <option value="Другое">Другое (указать!)</option>
                    </select>
                </div>

                <!-- Дополнительные поля для студента -->
                <div v-if="formData.activitytype === 'Студент'" class="form-group">
                    <label for="educationLevel">Уровень образования <span class="required">*</span></label>
                    <select id="educationLevel" name="educationLevel" class="form-input"
                        :required="formData.activitytype === 'Студент'" v-model="formData.educationlevel"
                        :disabled="isCorporateEmail">
                        <option value="" disabled>Выберите...</option>
                        <option value="Бакалавр">Бакалавр</option>
                        <option value="Магистр">Магистр</option>
                        <option value="Аспирант">Аспирант</option>
                    </select>
                </div>
                <div v-if="formData.activitytype === 'Студент'" class="form-group">
                    <label for="course">Курс <span class="required">*</span></label>
                    <select id="course" name="course" class="form-input" :required="formData.activitytype === 'Студент'"
                        v-model="formData.course" :disabled="isCorporateEmail">
                        <option value="" disabled>Выберите...</option>
                        <option v-for="n in 5" :key="n" :value="n">{{ n }}</option>
                    </select>
                </div>
                <div v-if="formData.activitytype === 'Студент'" class="form-group">
                    <label for="studyDirection">Направление обучения <span class="required">*</span></label>
                    <select id="studyDirection" name="studyDirection" class="form-input"
                        :required="formData.activitytype === 'Студент'" v-model="formData.studydirection"
                        :disabled="isCorporateEmail">
                        <option value="" disabled>Выберите...</option>
                        <option v-for="direction in studyDirections" :key="direction.code" :value="direction.code">{{
                            direction.name }}</option>
                    </select>
                </div>

                <!-- Дополнительные поля для преподавателя -->
                <div v-if="formData.activitytype === 'Преподаватель'">
                    <div class="form-group">
                        <label for="academicDegree">Ученая степень <span class="required">*</span></label>
                        <select id="academicDegree" name="academicDegree" class="form-input"
                            :required="formData.activitytype === 'Преподаватель'" v-model="formData.academicdegree"
                            :disabled="isCorporateEmail">
                            <option value="" disabled>Выберите...</option>
                            <option v-for="degree in academicDegrees" :key="degree.id" :value="degree.id">{{ degree.name
                            }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="academicTitle">Ученое звание <span class="required">*</span></label>
                        <select id="academicTitle" name="academicTitle" class="form-input"
                            :required="formData.activitytype === 'Преподаватель'" v-model="formData.academictitle"
                            :disabled="isCorporateEmail">
                            <option value="" disabled>Выберите...</option>
                            <option v-for="title in academicTitles" :key="title.id" :value="title.id">{{ title.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Дополнительные поля для "Другое" -->
                <div v-if="formData.activitytype === 'Другое'" class="form-group">
                    <label for="otherActivityType">Укажите вид деятельности <span class="required">*</span></label>
                    <input type="text" id="otherActivityType" name="otherActivityType" class="form-input"
                        placeholder="Укажите вид деятельности" :required="formData.activitytype === 'Другое'"
                        v-model="formData.otheractivitytype" :disabled="isCorporateEmail">
                </div>

                <!-- Факультет -->
                <div class="form-group"
                    v-if="formData.organizationname === 'ПГГПУ' || formData.organizationname === 'пггпу'">
                    <label for="faculty">Факультет (если есть)</label>
                    <select id="faculty" v-model="formData.faculty" class="form-input" :disabled="isCorporateEmail">
                        <option value="" disabled selected>Выберите факультет</option>
                        <option value="МФ">МФ</option>
                        <option value="ФФ">ФФ</option>
                        <option value="ФФК">ФФК</option>
                        <option value="ПиМНО">ПиМНО</option>
                        <option value="ППД">ППД</option>
                        <option value="ЕНФ">ЕНФ</option>
                        <option value="ИнЯз">ИнЯз</option>
                        <option value="ФизФ">ФизФ</option>
                        <option value="ИстФ">ИстФ</option>
                        <option value="МузФ">МузФ</option>
                        <option value="ПсФ">ПсФ</option>
                        <option value="ИНЭК">ИНЭК</option>
                        <option value="ПиСПО">ПиСПО</option>
                    </select>
                </div>
                <div class="form-group" v-else>
                    <label for="faculty">Факультет (если есть)</label>
                    <input id="faculty" type="text" class="form-input" placeholder="Введите факультет"
                        v-model="formData.faculty" :disabled="isCorporateEmail" />
                </div>

                <!-- Группа -->
                <div class="form-group">
                    <label for="user_group">Группа или класс (если есть)</label>
                    <input id="user_group" type="text" class="form-input" placeholder="Введите группу или класс"
                        v-model="formData.user_group" :disabled="isCorporateEmail" />
                </div>

                <!-- Страна -->
                <div class="form-group">
                    <label for="country">Страна <span class="required">*</span></label>
                    <select id="country" class="form-input" required v-model="formData.country"
                        :disabled="isCorporateEmail">
                        <option value="" disabled selected>Укажите страну</option>
                        <option v-for="country in countries" :key="country.name" :value="country.name">
                            {{ country.name }}
                        </option>
                    </select>
                </div>

                <!-- Федеральный округ -->
                <div class="form-group">
                    <label for="fedDistrict">Федеральный округ <span class="required">*</span></label>
                    <select id="fedDistrict" name="fedDistrict" class="form-input" :required="true"
                        v-model="formData.feddistrict" @change="loadRegions" :disabled="isCorporateEmail">
                        <option value="" disabled>Выберите...</option>
                        <option v-for="district in filteredFedDistricts" :key="district.id" :value="district.id">{{
                            district.name }}
                        </option>
                    </select>
                </div>

                <!-- Регион -->
                <div class="form-group">
                    <label for="region">Регион <span class="required">*</span></label>
                    <select id="region" name="region" class="form-input" :required="formData.feddistrict"
                        v-model="formData.region" :disabled="!formData.feddistrict || isCorporateEmail">
                        <option value="" disabled>Выберите...</option>
                        <option v-for="reg in regions" :key="reg.id" :value="reg.id">{{ reg.name }}</option>
                    </select>
                </div>

                <!-- Населенный пункт -->
                <div class="form-group">
                    <label for="city">Населенный пункт <span class="required">*</span></label>
                    <input type="text" id="city" name="city" class="form-input" :required="formData.region"
                        placeholder="Населенный пункт" v-model="formData.city"
                        :disabled="!formData.region || isCorporateEmail">
                </div>

                <!-- Телефон -->
                <div class="form-group">
                    <label for="phone">Телефон</label>
                    <input type="tel" id="phone" name="phone" class="form-input" placeholder="Телефон"
                        v-model="formData.phone" @input="formatPhone" :disabled="isCorporateEmail">
                </div>

                <!-- Согласие с обработкой персональных данных -->
                <div class="form-group checkbox-container">
                    <input type="checkbox" id="agree" name="agree" :required="true" :disabled="isCorporateEmail">Я даю
                    свое <a href="javascript:void(0);" @click="showAgreement">согласие</a> на обработку указанных выше
                    персональных данных <span class="required">*</span>
                </div>

                <!-- Кнопка регистрации -->
                <button type="submit" class="form-btn" :disabled="isCorporateEmail">Регистрация</button>

                <!-- Ссылка на вход -->
                <span>Уже есть аккаунт? <router-link :to="{ path: '/' }">Войти</router-link></span>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import zxcvbn from 'zxcvbn';
import navbar2 from '@/components/navbar2.vue';
import Swal from 'sweetalert2';

export default {
    name: 'Register',
    data() {
        return {
            formData: {
                email: '',
                password: '',
                confirmPassword: '',
                surname: '',
                name: '',
                thirdname: '',
                rolein: 'Гость',
                organizationname: '',
                faculty: '',
                user_group: '',
                country: '',
                passwordFieldType: 'password',
                confirmPasswordFieldType: 'password',
                organizationtype: '',
                customorganizationtype: '',
                activitytype: '',
                educationlevel: '',
                course: '',
                studydirection: '',
                otheractivitytype: '',
                feddistrict: '',
                region: '',
                city: '',
                phone: '',
                academicdegree: '',
                academictitle: '',
                organizationname: '',
                security: 'defaultuser',
            },
            passwordFieldType: 'password',
            confirmPasswordFieldType: 'password',
            showOrganizationTypeInput: false,
            showReportTopic: false,
            isCorporateEmail: false,
            studyDirections: [
                { code: '01', name: '01.xx.xx МАТЕМАТИКА И МЕХАНИКА' },
                { code: '02', name: '02.xx.xx КОМПЬЮТЕРНЫЕ И ИНФОРМАЦИОННЫЕ НАУКИ' },
                { code: '03', name: '03.xx.xx ФИЗИКА И АСТРОНОМИЯ' },
                { code: '04', name: '04.xx.xx ХИМИЯ' },
                { code: '05', name: '05.xx.xx НАУКИ О ЗЕМЛЕ' },
                { code: '06', name: '06.xx.xx БИОЛОГИЧЕСКИЕ НАУКИ' },
                { code: '07', name: '07.xx.xx АРХИТЕКТУРА' },
                { code: '08', name: '08.xx.xx ТЕХНИКА И ТЕХНОЛОГИИ СТРОИТЕЛЬСТВА' },
                { code: '09', name: '09.xx.xx ИНФОРМАТИКА И ВЫЧИСЛИТЕЛЬНАЯ ТЕХНИКА' },
                { code: '10', name: '10.xx.xx ИНФОРМАЦИОННАЯ БЕЗОПАСНОСТЬ' },
                { code: '11', name: '11.xx.xx ЭЛЕКТРОНИКА, РАДИОТЕХНИКА И СИСТЕМЫ СВЯЗИ' },
                { code: '12', name: '12.xx.xx ФОТОНИКА, ПРИБОРОСТРОЕНИЕ, ОПТИЧЕСКИЕ И БИОТЕХНИЧЕСКИЕ СИСТЕМЫ И ТЕХНОЛОГИИ' },
                { code: '13', name: '13.xx.xx ЭЛЕКТРО - И ТЕПЛОЭНЕРГЕТИКА' },
                { code: '14', name: '14.xx.xx ЯДЕРНАЯ ЭНЕРГЕТИКА И ТЕХНОЛОГИИ' },
                { code: '15', name: '15.xx.xx МАШИНОСТРОЕНИЕ' },
                { code: '16', name: '16.xx.xx ФИЗИКО-ТЕХНИЧЕСКИЕ НАУКИ И ТЕХНОЛОГИИ' },
                { code: '17', name: '17.xx.xx ОРУЖИЕ И СИСТЕМЫ ВООРУЖЕНИЯ' },
                { code: '18', name: '18.xx.xx ХИМИЧЕСКИЕ ТЕХНОЛОГИИ' },
                { code: '19', name: '19.xx.xx ПРОМЫШЛЕННАЯ ЭКОЛОГИЯ И БИОТЕХНОЛОГИИ' },
                { code: '20', name: '20.xx.xx ТЕХНОСФЕРНАЯ БЕЗОПАСНОСТЬ И ПРИРОДООБУСТРОЙСТВО' },
                { code: '21', name: '21.xx.xx ПРИКЛАДНАЯ ГЕОЛОГИЯ, ГОРНОЕ ДЕЛО, НЕФТЕГАЗОВОЕ ДЕЛО И ГЕОДЕЗИЯ' },
                { code: '22', name: '22.xx.xx ТЕХНОЛОГИИ МАТЕРИАЛОВ' },
                { code: '23', name: '23.xx.xx ТЕХНИКА И ТЕХНОЛОГИИ НАЗЕМНОГО ТРАНСПОРТА' },
                { code: '24', name: '24.xx.xx АВИАЦИОННАЯ И РАКЕТНО-КОСМИЧЕСКАЯ ТЕХНИКА' },
                { code: '25', name: '25.xx.xx АЭРОНАВИГАЦИЯ И ЭКСПЛУАТАЦИЯ АВИАЦИОННОЙ И РАКЕТНО-КОСМИЧЕСКОЙ ТЕХНИКИ' },
                { code: '26', name: '26.xx.xx ТЕХНИКА И ТЕХНОЛОГИИ КОРАБЛЕСТРОЕНИЯ И ВОДНОГО ТРАНСПОРТА' },
                { code: '27', name: '27.xx.xx УПРАВЛЕНИЕ В ТЕХНИЧЕСКИХ СИСТЕМАХ' },
                { code: '28', name: '28.xx.xx НАНОТЕХНОЛОГИИ И НАНОМАТЕРИАЛЫ' },
                { code: '29', name: '29.xx.xx ТЕХНОЛОГИИ ЛЕГКОЙ ПРОМЫШЛЕННОСТИ' },
                { code: '34', name: '34.xx.xx СЕСТРИНСКОЕ ДЕЛО' },
                { code: '35', name: '35.xx.xx СЕЛЬСКОЕ, ЛЕСНОЕ И РЫБНОЕ ХОЗЯЙСТВО' },
                { code: '36', name: '36.xx.xx ВЕТЕРИНАРИЯ И ЗООТЕХНИЯ' },
                { code: '37', name: '37.xx.xx ПСИХОЛОГИЧЕСКИЕ НАУКИ' },
                { code: '38', name: '38.xx.xx ЭКОНОМИКА И УПРАВЛЕНИЕ' },
                { code: '39', name: '39.xx.xx СОЦИОЛОГИЯ И СОЦИАЛЬНАЯ РАБОТА' },
                { code: '40', name: '40.xx.xx ЮРИСПРУДЕНЦИЯ' },
                { code: '41', name: '41.xx.xx ПОЛИТИЧЕСКИЕ НАУКИ И РЕГИОНОВЕДЕНИЕ' },
                { code: '42', name: '42.xx.xx СРЕДСТВА МАССОВОЙ ИНФОРМАЦИИ И ИНФОРМАЦИОННО-БИБЛИОТЕЧНОЕ ДЕЛО' },
                { code: '43', name: '43.xx.xx СЕРВИС И ТУРИЗМ' },
                { code: '44', name: '44.xx.xx ОБРАЗОВАНИЕ И ПЕДАГОГИЧЕСКИЕ НАУКИ' },
                { code: '45', name: '45.xx.xx ЯЗЫКОЗНАНИЕ И ЛИТЕРАТУРОВЕДЕНИЕ' },
                { code: '46', name: '46.xx.xx ИСТОРИЯ И АРХЕОЛОГИЯ' },
                { code: '47', name: '47.xx.xx ФИЛОСОФИЯ, ЭТИКА И РЕЛИГИОВЕДЕНИЕ' },
                { code: '48', name: '48.xx.xx ТЕОЛОГИЯ' },
                { code: '49', name: '49.xx.xx ФИЗИЧЕСКАЯ КУЛЬТУРА И СПОРТ' },
                { code: '50', name: '50.xx.xx ИСКУССТВОЗНАНИЕ' },
                { code: '51', name: '51.xx.xx КУЛЬТУРОВЕДЕНИЕ И СОЦИОКУЛЬТУРНЫЕ ПРОЕКТЫ' },
                { code: '52', name: '52.xx.xx СЦЕНИЧЕСКИЕ ИСКУССТВА И ЛИТЕРАТУРНОЕ ТВОРЧЕСТВО' },
                { code: '53', name: '53.xx.xx МУЗЫКАЛЬНОЕ ИСКУССТВО' },
                { code: '54', name: '54.xx.xx ИЗОБРАЗИТЕЛЬНОЕ И ПРИКЛАДНЫЕ ВИДЫ ИСКУССТВ' }
            ],
        };
    },
    components: {
        navbar2,
    },
    computed: {
        ...mapState('references', ['countries', 'fedDistricts', 'regions', 'academicDegrees', 'academicTitles', 'organizationTypes', 'participationForms']),
        passwordStrength() {
            if (!this.formData.password) return 0;
            const result = zxcvbn(this.formData.password);
            return result.score;
        },
        passwordStatus() {
            switch (this.passwordStrength) {
                case 0:
                    return 'Очень слабый';
                case 1:
                    return 'Слабый';
                case 2:
                    return 'Средний';
                case 3:
                    return 'Надежный';
                case 4:
                    return 'Очень надежный';
                default:
                    return '';
            }
        },
        filteredFedDistricts() {
            if (!this.fedDistricts) return [];
            if (this.formData.country === 'Россия') {
                return this.fedDistricts.filter(district => district.name !== 'Другое');
            }
            return this.fedDistricts;
        },
    },
    methods: {
        showAgreement() {
        Swal.fire({
            icon: 'info',
            title: 'Cогласие с обработкой перс.данных',
            text: 'Я даю свое согласие на обработку указанных выше персональных данных ФГБОУ ВО «Пермский государственный гуманитарно-педагогический университет» в соответствии с Федеральным законом "О персональных данных" от 27.07.2006 N 152-ФЗ (http://www.consultant.ru/document/cons_doc_LAW_61801/), в целях реализации образовательных и/или консультационных программ, а также получения сообщений рекламно-информационного характера о мероприятиях и других услугах ФГБОУ ВО "ПГГПУ".',
            confirmButtonText: 'ОК',
            confirmButtonColor: '#3c218c',
        });
    },
        getTextValue(uid, list) {
            if (!list) return ''; // Если список не передан, возвращаем пустую строку
            const item = list.find(item => item.id === uid);
            return item ? item.name : '';
        },
        ...mapActions({
            register: 'auth/register',
            getcountries: 'references/getcountries',
            getfeddistricts: 'references/getfeddistricts',
            getregionsbyfeddistrictid: 'references/getregionsbyfeddistrictid',
            getacademicdegrees: 'references/getacademicdegrees',
            getacademictitles: 'references/getacademictitles',
            getorganizationtypes: 'references/getorganizationtypes',
            getparticipationforms: 'references/getparticipationforms',
        }),
        checkEmailDomain() {
            this.isCorporateEmail = this.formData.email.endsWith('@pspu.ru');
        },
        async go_register() {
            if (this.isCorporateEmail) {
                Swal.fire({
                    icon: 'info',
                    title: 'Корпоративный email',
                    text: 'Пожалуйста, перейдите на страницу входа и войдите с помощью корпоративных данных.',
                    confirmButtonText: 'ОК',
                    confirmButtonColor: '#3c218c',
                });
                return;
            }

            if (this.formData.password !== this.formData.confirmPassword) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка',
                    text: 'Пароли не совпадают!',
                    confirmButtonText: 'ОК',
                    confirmButtonColor: '#3c218c',
                });
                return;
            }

            const formData = {
                email: this.formData.email,
                password: this.formData.password,
                surname: this.formData.surname,
                name: this.formData.name,
                thirdname: this.formData.thirdname || null,
                rolein: this.formData.rolein,
                faculty: this.formData.faculty || null,
                user_group: this.formData.user_group || null,
                country: this.formData.country,
                authType: 'jwt',
                activitytype: this.formData.activitytype,
                educationlevel: this.formData.educationlevel || null,
                course: this.formData.course || null,
                studydirection: this.formData.studydirection || null,
                otheractivitytype: this.formData.otheractivitytype || null,
                feddistrict: this.getTextValue(this.formData.feddistrict, this.fedDistricts),
                region: this.getTextValue(this.formData.region, this.regions),
                city: this.formData.city,
                phone: this.formData.phone,
                academicdegree: this.getTextValue(this.formData.academicdegree, this.academicDegrees),
                academictitle: this.getTextValue(this.formData.academictitle, this.academicTitles),
                organizationtype: this.getTextValue(this.formData.organizationtype, this.organizationTypes),
                customorganizationtype: this.formData.customorganizationtype || null,
                organization: this.formData.organizationname,
                security: this.formData.security,
            };

            try {
                await this.register(formData);
                Swal.fire({
                    icon: 'success',
                    title: 'Успех',
                    text: 'Вы успешно зарегистрировались! Теперь войдите в систему.',
                    confirmButtonText: 'ОК',
                    confirmButtonColor: '#3c218c',
                });
                this.$router.push('/');
            } catch (error) {
                if (error.response && error.response.status === 405) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: 'Пользователь с таким email уже зарегистрирован. Выполните вход.',
                        confirmButtonText: 'ОК',
                        confirmButtonColor: '#3c218c',
                    });
                } else {
                    alert('Ошибка регистрации: ' + (error.response ? error.response.data.message : error.message));
                }
            }
        },
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        toggleConfirmPasswordVisibility() {
            this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
        },
        handleOrganizationTypeChange() {
            this.showOrganizationTypeInput = this.formData.organizationtype === '13';
        },
        handleActivityTypeChange() {
            if (this.formData.activitytype === 'Студент') {
                this.formData.academicdegree = '';
                this.formData.academictitle = '';
            } else if (this.formData.activitytype === 'Преподаватель') {
                this.formData.educationlevel = '';
                this.formData.course = '';
                this.formData.studydirection = '';
            } else if (this.formData.activitytype === 'Другое') {
                this.formData.academicdegree = '';
                this.formData.academictitle = '';
                this.formData.educationlevel = '';
                this.formData.course = '';
                this.formData.studydirection = '';
            }
        },
        formatPhone(event) {
            let phone = event.target.value.replace(/\D/g, '');
            if (phone.length > 0 && phone[0] !== '+') {
                phone = '+' + phone;
            }
            this.formData.phone = phone;
        },
        async loadRegions() {
            if (!this.formData.feddistrict) return;
            await this.getregionsbyfeddistrictid({ feddistrictid: this.formData.feddistrict });
        },
        clearUserData() {
            localStorage.clear();
            this.$store.commit('user/setUser', null);
        }
    },
    created() {
        this.clearUserData();
        this.$nextTick(() => {
            window.scrollTo(0, 0);
        });
        this.getcountries();
        this.getfeddistricts();
        this.getacademicdegrees();
        this.getacademictitles();
        this.getorganizationtypes();
        this.getparticipationforms();
    },
    mounted() {
        window.scrollTo(0, 0);
    },
};
</script>

<style scoped>
.required-note {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
}

.required {
    color: red;
}

.password-hint {
    font-size: 12px;
    color: #888;
    margin-bottom: 10px;
}

.password-strength {
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.strength-bar {
    height: 5px;
    border-radius: 2px;
    margin-top: 5px;
    transition: width 0.3s;
}

.strength-0 {
    background-color: red;
    width: 20%;
}

.strength-1 {
    background-color: orange;
    width: 40%;
}

.strength-2 {
    background-color: yellow;
    width: 60%;
}

.strength-3 {
    background-color: lightgreen;
    width: 80%;
}

.strength-4 {
    background-color: green;
    width: 100%;
}

.strength-status {
    margin-left: 10px;
    font-size: 14px;
    color: #888;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    text-align: left;
    padding-left: 1em;
}

.password-input {
    position: relative;
    display: flex;
    align-items: center;
}

.eye-button {
    background: none;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 1em;
    margin-left: -30px;
    z-index: 10;
}

.home {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

form {
    display: flex;
    padding: 20px;
    flex-direction: column;
    width: 600px;
    row-gap: 20px;
    border: 1px solid #ef7d00;
    border-radius: 20px;
    background-color: #fff;
}

@media screen and (max-width: 768px) {
    form {
        padding: 10px;
        width: 80%;
    }
}

.content {
    margin-top: 70px;
    text-align: center;
}

h2 {
    color: #000000;
    font-size: 2em;
}

.form-input {
    padding: 15px;
    border: none;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f5f5f5;
}

.form-btn {
    background-color: #ef7d00;
    color: white;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    margin: 10px auto;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.form-btn:hover {
    background-color: #3c218c;
}

a {
    color: #3c218c;
    text-decoration: none;
}

a:hover {
    color: #ef7d00;
}

.corporate-email-warning {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    color: #856404;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}
</style>