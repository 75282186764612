<template>
  <div class="attendance-report">
    <h2>Отчет по пользователям</h2>
    <div class="search-sort">
      <input v-model="searchQuery" placeholder="Поиск по всем столбцам" />
    </div>
    <button class="generate-report-btn" @click="generateReportForSelectedUsers">
      Создать отчет для выбранных
    </button>
    <div v-if="!selectedUser">
      <h3>Выберите пользователя:</h3>
      <table class="users-table">
        <thead>
          <tr>
            <th v-for="col in userColumns" :key="col.key">
              <div class="header-cell">
                <span>{{ col.label }}</span>
                <button @click="sortTable(col.key)">
                  {{ sortBy === col.key ? (sortAsc ? '▲' : '▼') : '↕' }}
                </button>
              </div>
            </th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.uid">
            <td v-for="col in userColumns" :key="col.key">{{ user[col.key] }}</td>
            <td>
              <input type="checkbox" v-model="selectedUsers" :value="user.uid" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h3>Информация о пользователе "{{ selectedUser.surname }} {{ selectedUser.name }}"</h3>
      <button class="back-btn" @click="resetUserSelection">
        <i class="fas fa-arrow-left"></i> Вернуться к выбору пользователя
      </button>

      <div v-if="!selectedReportType">
        <h4>Выберите тип отчета:</h4>
        <button @click="selectReportType('full')">Полный отчет</button>
        <button @click="selectReportType('info')">Отчет с информацией</button>
      </div>

      <div v-else>
        <button class="back-btn" @click="resetReportType">
          <i class="fas fa-arrow-left"></i> Вернуться к выбору типа отчета
        </button>

        <div v-if="selectedReportType === 'info'">
          <h4>Информация о пользователе "{{ selectedUser.surname }} {{ selectedUser.name }}"</h4>
          <div class="column-selection">
            <h5>Выберите столбцы для отображения:</h5>
            <button @click="selectAll('users')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in userColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.users" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <table class="info-table">
            <thead>
              <tr>
                <th>Атрибут</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="col in selectedColumns.users" :key="col">
                <td>{{ userColumns.find(c => c.key === col).label }}</td>
                <td>{{ selectedUser[col] }}</td>
              </tr>
            </tbody>
          </table>
          <button @click="exportToExcel">Скачать в Excel</button>
        </div>

        <div v-else-if="selectedReportType === 'full'" class="column-selection">
          <h4>Выберите столбцы для отчета:</h4>
          <div class="column-group">
            <h5>О пользователе</h5>
            <button @click="selectAll('users')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in userColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.users" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О регистрации</h5>
            <button @click="selectAll('registration')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in registrationColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.registration" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О присутствии</h5>
            <button @click="selectAll('attendance')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in attendanceColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.attendance" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
        </div>

        <div v-if="selectedReportType === 'full'">
          <h4>Отчет по пользователю "{{ selectedUser.name }} {{ selectedUser.surname }}"</h4>
          <button @click="exportToExcel">Скачать в Excel</button>
          <table class="full-report-table">
            <thead>
              <tr>
                <th v-for="col in selectedColumns.users" :key="col">{{ userColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.registration" :key="col">{{ registrationColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.attendance" :key="col">{{ attendanceColumns.find(c => c.key === col).label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(registration, index) in combinedData" :key="index">
                <td v-for="col in selectedColumns.users" :key="col">{{ selectedUser[col] }}</td>
                <td v-for="col in selectedColumns.registration" :key="col">{{ registration[col] }}</td>
                <td v-for="col in selectedColumns.attendance" :key="col">{{ registration.attendance ? registration.attendance[col] : 'Нет данных' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  data() {
    return {
      selectedUser: null,
      selectedUsers: [],
      selectedReportType: null,
      searchQuery: '',
      sortBy: 'name',
      sortAsc: true,
      selectedColumns: {
        users: [],
        registration: [],
        attendance: [],
      },
      userColumns: [
        // { key: 'uid', label: 'Уникальный идентификатор' },
        { key: 'email', label: 'Email' },
        { key: 'name', label: 'Имя' },
        { key: 'surname', label: 'Фамилия' },
        { key: 'thirdname', label: 'Отчество' },
        { key: 'organizationname', label: 'Организация' },
      ],
      registrationColumns: [
        { key: 'activitytype', label: 'Тип активности' },
        { key: 'organizationname', label: 'Организация' },
      ],
      attendanceColumns: [
        { key: 'scannedenter', label: 'Время входа' },
        { key: 'scannedexit', label: 'Время выхода' },
      ],
    };
  },
  computed: {
    ...mapState('user', ['users']),
    ...mapState('eventregistration', ['registrations']),
    ...mapState('attendance', ['attendances']),
    filteredUsers() {
      let users = this.users.filter(user =>
        Object.values(user).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
      ));
      if (this.sortBy) {
        users.sort((a, b) => {
          const aValue = a[this.sortBy];
          const bValue = b[this.sortBy];
          if (aValue < bValue) return this.sortAsc ? -1 : 1;
          if (aValue > bValue) return this.sortAsc ? 1 : -1;
          return 0;
        });
      }
      return users;
    },
    combinedData() {
      return this.registrations.map(registration => {
        const attendance = this.attendances.find(att => att.useruid === registration.userid);
        return { ...registration, attendance };
      });
    },
  },
  methods: {
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('eventregistration', ['getEventRegistrationsByUserUid']),
    ...mapActions('attendance', ['getAttendanceByUserUid']),
    async selectUser(userUid) {
      this.selectedUser = this.users.find(user => user.uid === userUid);
      this.registrations = await this.getEventRegistrationsByUserUid(userUid);
      this.attendances = await this.getAttendanceByUserUid(userUid);
    },
    resetUserSelection() {
      this.selectedUser = null;
      this.selectedReportType = null;
      this.registrations = [];
      this.attendances = [];
    },
    selectReportType(type) {
      this.selectedReportType = type;
    },
    resetReportType() {
      this.selectedReportType = null;
    },
    selectAll(group) {
      if (group === 'users') {
        this.selectedColumns.users = this.userColumns.map(col => col.key);
      } else if (group === 'registration') {
        this.selectedColumns.registration = this.registrationColumns.map(col => col.key);
      } else if (group === 'attendance') {
        this.selectedColumns.attendance = this.attendanceColumns.map(col => col.key);
      }
    },
    sortTable(column) {
      if (this.sortBy === column) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = column;
        this.sortAsc = true;
      }
    },
    async generateReportForSelectedUsers() {
      if (this.selectedUsers.length === 0) return;
      // Логика для генерации отчета по нескольким пользователям
    },
    exportToExcel() {
      const data = this.combinedData.map(row => ({
        ...row,
        userName: `${this.selectedUser.surname} ${this.selectedUser.name}`,
      }));
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Отчет');
      XLSX.writeFile(wb, `Отчет_${this.selectedUser.name}_${this.selectedUser.surname}.xlsx`);
    },
  },
  beforeCreate() {
      window.scrollTo(0, 0);
  },
  async created() {
    await this.getAllUsers();
  },
};
</script>

<style scoped>
.attendance-report {
  padding: 20px;
  padding-top: 50px;
  position: relative;
}

.search-sort {
  margin-bottom: 20px;
}

.generate-report-btn {
  position: fixed;
  top: 80px;
  right: 20px;
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

.generate-report-btn:hover {
  background-color: #5a3aa8;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #3c218c;
  color: white;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
}

button:hover {
  background-color: #5a3aa8;
}

.back-btn {
  background-color: #6c757d;
}

.back-btn:hover {
  background-color: #5a6268;
}

.column-selection {
  margin-bottom: 20px;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.header-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-cell button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
}
</style>