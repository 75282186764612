  <template>
    <div>
      <navbar />
      <div v-if="user" class="attendance-report">
        <h1>Отчет о присутствии на мероприятие "{{ eventName }}"</h1>
        <button @click="downloadExcel">Скачать в Excel</button>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Фамилия</th>
              <th>Имя</th>
              <th>Отчество</th>
              <th>Сканер вход</th>
              <th>Сканер выход</th>
              <th>Время входа</th>
              <th>Время выхода</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="attendance in attendancesWithUserData" :key="attendance.id">
              <td>{{ attendance.userEmail }}</td>
              <td>{{ attendance.userSurname }}</td>
              <td>{{ attendance.userName }}</td>
              <td>{{ attendance.userThirdname }}</td>
              <td>{{ attendance.scannerEnterName }}</td>
              <td>{{ attendance.scannerExitName }}</td>
              <td>{{ formatDate(attendance.scannedenter) }}</td>
              <td>{{ attendance.scannedexit ? formatDate(attendance.scannedexit) : 'Не зафиксировано' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="auth-required-message">
        <h2>Доступ запрещен</h2>
        <p v-if="!user">Для просмотра этой страницы необходимо авторизоваться.</p>
        <p v-else>У вас недостаточно прав для просмотра этой страницы.</p>
        <button class="form-btn" @click="goToLogin">
          <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
        </button>
      </div>
    </div>
  </template>

  <script>
  import navbar from "@/components/navbar.vue";
  import { mapActions, mapGetters, mapState } from 'vuex';
  import * as XLSX from 'xlsx';

  export default {
    components: {
      navbar,
    },
    props: {
      eventName: {
        type: String,
        required: true
      },
      eventId: {
        type: String,
        required: true
      }
    },
    computed: {
      eventName() {
        return decodeURIComponent(this.$route.params.eventName); // Декодируем название мероприятия
      },
      ...mapState('user', ['user']),
      ...mapGetters('attendance', ['attendances']),
      ...mapGetters('user', ['user', 'users']), // Добавляем геттер для всех пользователей
      attendancesWithUserData() {
        // Сопоставляем данные о посещениях с данными пользователей
        return this.attendances.map(attendance => {
          const user = this.users.find(u => u.uid === attendance.useruid);
          const scannerEnterUser = this.users.find(u => u.uid === attendance.scanuidenter);
          const scannerExitUser = this.users.find(u => u.uid === attendance.scanuidexit);

          return {
            ...attendance,
            userEmail: user ? user.email : 'Неизвестно',
            userSurname: user ? user.surname : 'Неизвестно',
            userName: user ? user.name : 'Неизвестно',
            userThirdname: user ? user.thirdname : 'Неизвестно',
            scannerEnterName: scannerEnterUser ? `${scannerEnterUser.surname} ${scannerEnterUser.name} ${scannerEnterUser.thirdname}` : 'Неизвестно',
            scannerExitName: scannerExitUser ? `${scannerExitUser.surname} ${scannerExitUser.name} ${scannerExitUser.thirdname}` : 'Неизвестно',
          };
        });
      }
    },
    methods: {
      ...mapActions({
        getUserByUid: 'user/getUserByUid',
        // logoutAction: 'user/logout'
      }),
      ...mapActions('attendance', ['getAttendanceByEventUid']),
      ...mapActions('user', ['getAllUsers']), // Добавляем действие для загрузки всех пользователей
      downloadExcel() {
        const data = this.attendancesWithUserData.map(attendance => ({
          'Email': attendance.userEmail,
          'Фамилия': attendance.userSurname,
          'Имя': attendance.userName,
          'Отчество': attendance.userThirdname,
          'Сканер вход': attendance.scannerEnterName,
          'Сканер выход': attendance.scannerExitName,
          'Время входа': attendance.scannedenter,
          'Время выхода': attendance.scannedexit,
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Присутствие');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        this.saveAsExcelFile(excelBuffer, 'attendance.xlsx');
      },
      saveAsExcelFile(buffer, fileName) {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      formatDate(date) {
        return new Date(date).toLocaleString();
      },
      goToLogin() {
        this.$router.push('/login');
      },
    },
    async created() {
      await this.getUserByUid();
      await this.getAllUsers(); // Загружаем всех пользователей
      await this.getAttendanceByEventUid(this.eventId); // Загружаем данные о посещениях
    },
    mounted() {
      window.scrollTo(0, 0);
    },
  };
  </script>

<style scoped>
.attendance-report {
  padding: 20px;
  padding-top: 50px
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #3c218c;
  color: white;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
}

button:hover {
  background-color: #5a3aa8;
}

.auth-required-message {
  text-align: center;
  padding: 20px;
  padding-top: 50px
}

.auth-required-message h2 {
  color: #ff0000;
}

.form-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.form-btn:hover {
  background-color: #5a3aa8;
}
</style>