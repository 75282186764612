<template>
  <main class="main-content">
    <router-view />

    <!-- Кнопка "Назад" -->
    <BackButton />

    <!-- Кнопка "Наверх" -->
    <ScrollToTopButton />

  </main>
  <customfooter />
</template>
<script>
import customfooter from "@/components/footer.vue";
import BackButton from "@/components/BackButton.vue";
import ScrollToTopButton from "@/components/ScrollToTopButton.vue";

export default {
  components: {
    customfooter,
    BackButton,
    ScrollToTopButton,
  },
}
</script>
<style>
@font-face {
  font-family: 'Golos';
  src: url('./assets/fonts/golostext_demibold.ttf') format('truetype');
  /* Путь к шрифту */
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: 'Golos', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Минимальная высота на весь экран */
}
.main-content {
  flex: 1; /* Растягивает контент, чтобы футер был внизу */
}
nav {
  padding: 30px;
}
/* Стили для SweetAlert2 */
.custom-swal-popup {
  font-family: 'Golos', sans-serif !important; /* Шрифт Golos */
}

.custom-swal-title {
  color: #3c218c !important; /* Цвет заголовка */
  font-size: 24px !important;
  font-weight: bold !important;
}

.custom-swal-content {
  color: #2c3e50 !important; /* Цвет текста */
  font-size: 16px !important;
}

.custom-swal-confirm-button {
  background-color: #3c218c !important; /* Цвет кнопки */
  border: none !important;
  font-size: 16px !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
}

.custom-swal-confirm-button:hover {
  background-color: #2a175f !important; /* Цвет кнопки при наведении */
}
</style>
