<template>
  <div class="attendance-report">
    <h2>Отчет по мероприятиям</h2>
    <div class="search-sort">
      <input v-model="searchQuery" placeholder="Поиск по всем столбцам" />
    </div>
    <button class="generate-report-btn" @click="generateReportForSelectedEvents">
      Создать отчет для выбранных
    </button>
    <div v-if="!selectedEvent">
      <h3>Выберите мероприятие:</h3>
      <table class="events-table">
        <thead>
          <tr>
            <th v-for="col in eventColumns" :key="col.key">
              <div class="header-cell">
                <span>{{ col.label }}</span>
                <button @click="sortTable(col.key)">
                  {{ sortBy === col.key ? (sortAsc ? '▲' : '▼') : '↕' }}
                </button>
              </div>
            </th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in filteredEvents" :key="event.uid">
            <td v-for="col in eventColumns" :key="col.key">{{ event[col.key] }}</td>
            <td>
              <input type="checkbox" v-model="selectedEvents" :value="event.uid" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h3>Участники мероприятия "{{ selectedEvent.name }}"</h3>
      <button class="back-btn" @click="resetEventSelection">
        <i class="fas fa-arrow-left"></i> Вернуться к выбору мероприятия
      </button>

      <div v-if="!selectedReportType">
        <h4>Выберите тип отчета:</h4>
        <button @click="selectReportType('full')">Полный отчет о мероприятии</button>
        <button @click="selectReportType('info')">Отчет с информацией о мероприятии</button>
      </div>

      <div v-else>
        <button class="back-btn" @click="resetReportType">
          <i class="fas fa-arrow-left"></i> Вернуться к выбору типа отчета
        </button>

        <div v-if="selectedReportType === 'info'">
          <h4>Информация о мероприятии "{{ selectedEvent.name }}"</h4>
          <div class="column-selection">
            <h5>Выберите столбцы для отображения:</h5>
            <button @click="selectAll('events')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in eventColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.events" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <table class="info-table">
            <thead>
              <tr>
                <th>Атрибут</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="col in selectedColumns.events" :key="col">
                <td>{{ eventColumns.find(c => c.key === col).label }}</td>
                <td>{{ selectedEvent[col] }}</td>
              </tr>
            </tbody>
          </table>
          <button @click="exportToExcel">Скачать в Excel</button> 
        </div>

        <div v-else-if="selectedReportType === 'full'" class="column-selection">
          <h4>Выберите столбцы для отчета:</h4>
          <div class="column-group">
            <h5>О мероприятии</h5>
            <button @click="selectAll('events')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in eventColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.events" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О пользователе</h5>
            <button @click="selectAll('users')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in userColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.users" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О регистрации</h5>
            <button @click="selectAll('registration')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in registrationColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.registration" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
          <div class="column-group">
            <h5>О присутствии</h5>
            <button @click="selectAll('attendance')">Выбрать все</button>
            <div class="checkbox-grid">
              <label v-for="col in attendanceColumns" :key="col.key">
                <input type="checkbox" v-model="selectedColumns.attendance" :value="col.key"> {{ col.label }}
              </label>
            </div>
          </div>
        </div>

        <div v-if="selectedReportType === 'full'">
          <h4>Отчет по мероприятию "{{ selectedEvent.name }}"</h4>
          <button @click="exportToExcel">Скачать в Excel</button> 
          <table class="full-report-table">
            <thead>
              <tr>
                <th v-for="col in selectedColumns.events" :key="col">{{ eventColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.users" :key="col">{{ userColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.registration" :key="col">{{ registrationColumns.find(c => c.key === col).label }}</th>
                <th v-for="col in selectedColumns.attendance" :key="col">{{ attendanceColumns.find(c => c.key === col).label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(attendance, index) in combinedData" :key="index">
                <td v-for="col in selectedColumns.events" :key="col">{{ selectedEvent[col] }}</td>
                <td v-for="col in selectedColumns.users" :key="col">{{ attendance.user ? attendance.user[col] : '' }}</td>
                <td v-for="col in selectedColumns.registration" :key="col">{{ attendance.registration ? attendance.registration[col] : '' }}</td>
                <td v-for="col in selectedColumns.attendance" :key="col">
                  <template v-if="col === 'scannedenter' || col === 'scannedexit'">
                    {{ formatDateTime(attendance[col]) }}
                  </template>
                  <template v-else>
                    {{ attendance[col] }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  data() {
    return {
      selectedEvent: null,
      selectedEvents: [],
      selectedReportType: null,
      searchQuery: '',
      sortBy: 'name',
      sortAsc: true,
      selectedColumns: {
        events: [],
        users: [],
        attendance: [],
        registration: []
      },
      eventColumns: [
        { key: 'name', label: 'Название мероприятия' },
        { key: 'datestart', label: 'Дата начала' },
        { key: 'dateend', label: 'Дата окончания' },
        { key: 'organizator', label: 'Организатор' },
      ],
      userColumns: [
        { key: 'email', label: 'Электронная почта' },
        { key: 'name', label: 'Имя' },
        { key: 'surname', label: 'Фамилия' },
        { key: 'thirdname', label: 'Отчество' },
        { key: 'organizationname', label: 'Название организации' },
      ],
      attendanceColumns: [
        { key: 'scannedenter', label: 'Время входа' },
        { key: 'scannedexit', label: 'Время выхода' },
      ],
      registrationColumns: [
        { key: 'activitytype', label: 'Тип активности' },
        { key: 'organizationname', label: 'Название организации' },
      ],
    };
  },
  computed: {
    ...mapState('event', ['events']),
    ...mapState('eventregistration', ['registrations']),
    ...mapState('attendance', ['attendances']),
    ...mapState('references', ['organizators']),
    filteredEvents() {
      let events = this.events.filter(event =>
        Object.values(event).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
      ));
      if (this.sortBy) {
        events.sort((a, b) => {
          const aValue = a[this.sortBy];
          const bValue = b[this.sortBy];
          if (aValue < bValue) return this.sortAsc ? -1 : 1;
          if (aValue > bValue) return this.sortAsc ? 1 : -1;
          return 0;
        });
      }
      return events;
    },
    combinedData() {
      return this.attendances.map(attendance => {
        const registration = this.registrations.find(reg => reg.userid === attendance.useruid);
        return { ...attendance, registration };
      });
    }
  },
  methods: {
    ...mapActions('event', ['getallevents']),
    ...mapActions('attendance', ['getAttendanceByEventUid']),
    ...mapActions('eventregistration', ['getEventRegistrationsByEventUid']),
    ...mapActions('references', ['getorganizators']),
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      return d.toLocaleDateString();
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const dt = new Date(dateTime);
      return dt.toLocaleString();
    },
    async selectEvent(eventUid) {
      this.selectedEvent = this.events.find(event => event.uid === eventUid);
      this.attendances = await this.getAttendanceByEventUid(eventUid);
      this.registrations = await this.getEventRegistrationsByEventUid(eventUid);
    },
    resetEventSelection() {
      this.selectedEvent = null;
      this.attendances = [];
      this.registrations = [];
      this.selectedReportType = null;
    },
    selectReportType(type) {
      this.selectedReportType = type;
    },
    resetReportType() {
      this.selectedReportType = null;
    },
    selectAll(group) {
      if (group === 'events') {
        this.selectedColumns.events = this.eventColumns.map(col => col.key);
      } else if (group === 'users') {
        this.selectedColumns.users = this.userColumns.map(col => col.key);
      } else if (group === 'registration') {
        this.selectedColumns.registration = this.registrationColumns.map(col => col.key);
      } else if (group === 'attendance') {
        this.selectedColumns.attendance = this.attendanceColumns.map(col => col.key);
      }
    },
    sortTable(column) {
      if (this.sortBy === column) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = column;
        this.sortAsc = true;
      }
    },
    async generateReportForSelectedEvents() {
      if (this.selectedEvents.length === 0) return;
      // Логика для генерации отчета по нескольким мероприятиям
    },
    exportToExcel() {
      const data = this.combinedData.map(row => ({
        ...row,
        eventName: this.selectedEvent.name,
        eventDate: this.formatDate(this.selectedEvent.datestart),
      }));
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Отчет');
      XLSX.writeFile(wb, `Отчет_${this.selectedEvent.name}.xlsx`);
    },
  },
  beforeCreate() {
      window.scrollTo(0, 0);
  },
  async created() {
    await this.getallevents();
    await this.getorganizators();
  },
};
</script>

<style scoped>
.attendance-report {
  padding: 20px;
  padding-top: 50px;
  position: relative;
}

.search-sort {
  margin-bottom: 20px;
}

.generate-report-btn {
  position: fixed;
  top: 80px;
  right: 20px;
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

.generate-report-btn:hover {
  background-color: #5a3aa8;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #3c218c;
  color: white;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
}

button:hover {
  background-color: #5a3aa8;
}

.back-btn {
  background-color: #6c757d;
}

.back-btn:hover {
  background-color: #5a6268;
}

.column-selection {
  margin-bottom: 20px;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.header-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-cell button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
}
</style>