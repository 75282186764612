<template>
  <button class="back-button" @click="goBack" v-if="showBackButton">
    <i class="fas fa-arrow-left"></i>
  </button>
</template>

<script>
export default {
  computed: {
    showBackButton() {
      // Список страниц, на которых кнопка "Назад" не отображается
      const excludedRoutes = ['/', '/login', '/register', '/EventsView'];
      return !excludedRoutes.includes(this.$route.path);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Возврат на предыдущую страницу
    }
  }
}
</script>

<style scoped>
.back-button {
  position: fixed;
  top: 80px; /* Отступ ниже навбара (60px высота навбара + 20px отступ) */
  left: 20px; /* Левый верхний угол */
  background-color: #3c218c; /* Цвет как у кнопки "Наверх" */
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001; /* Убедимся, что кнопка поверх других элементов */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9); /* Тень для красоты */
}

.back-button:hover {
  background-color: #ef7d00; /* Цвет при наведении */
}

/* Скрываем кнопку на мобильных устройствах */
@media (max-width: 768px) {
  .back-button {
    display: none;
  }
}
</style>